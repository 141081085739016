import React, { useContext, useState } from 'react'

import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded'
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded'
import AnnouncementRoundedIcon from '@mui/icons-material/AnnouncementRounded'
import BookmarkBorderRoundedIcon from '@mui/icons-material/BookmarkBorderRounded'
import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded'

import { neutral50White } from '../../../style'
import { request } from '../../../helpers/request'
import { AppContext, UserAuthType } from '../../../contexts/AppContext'
import { logSMEvent } from '../../../events/logEvent'
import { Discount } from '../../../types/discount'
import { SMHorizontalDiv } from '../../../SMComponents/SMView'
import { SMIconButton } from '../../../SMComponents/SMButton'
import { DiscountContext } from '../../../contexts/DiscountContext'
import {
  LIKE_DISCOUNTS_FIELDS_REQUIRED,
  SAVE_DISCOUNTS_FIELDS_REQUIRED,
  isAuthAllowedToLikeDiscounts,
  isAuthAllowedToSaveDiscounts,
} from '../../../consts/loginRestrictions'
import { SMP } from '../../../SMComponents/SMText'
import { CTAButton, CTAButtonSource } from './CTAButton'
import { BookTableButton } from './BookTableButton'

export const DiscountActionFooter = ({ discount }: { discount: Discount }) => {
  const { auth, setAuth, handleOpenSnackBar } = useContext(AppContext)
  const { handleOpenLoginDrawer } = useContext(DiscountContext)
  const [isSaved, setIsSaved] = useState<boolean>(
    (auth && auth?.savedDiscounts?.includes(discount.id)) || false
  )

  const [isLike, setIsLike] = useState<boolean>(
    (auth && auth?.likedDiscounts?.includes(discount.id)) || false
  )

  const handleLikeDiscount = () => {
    const handleAction = async (userAuth: UserAuthType) => {
      if (!isLike) {
        logSMEvent('DISCOUNT_LIKED', {
          id: discount.id,
          url: discount.url,
          mainTagV3: discount.mainTagV3,
          club: discount.club,
        })
      }
      setIsLike(!isLike)
      const response = await request(
        'like_discount',
        {
          method: 'POST',
          body: JSON.stringify({
            discountId: discount.id,
            like: !isLike,
          }),
        },
        userAuth
      )
      const newListOfDiscountsLiked = (response?.data?.likedDiscounts as string[]) || null
      setAuth({
        ...userAuth,
        likedDiscounts: newListOfDiscountsLiked ? newListOfDiscountsLiked : userAuth.likedDiscounts,
      })
    }
    if (!auth || !isAuthAllowedToLikeDiscounts(auth)) {
      handleOpenLoginDrawer({
        source: 'like_button_2',
        title: 'Completa tu perfil para dar likes',
        subtitle:
          'Además podrás guardar descuentos, reservar mesas, acceder a beneficios exclusivos y más.',
        fields: LIKE_DISCOUNTS_FIELDS_REQUIRED,
        buttonLabel: 'Dar like',
        onSubmit: (userAuth) => {
          handleAction(userAuth)
          handleOpenSnackBar({
            message: 'Tu perfil y tu like se han guardado correctamente. 👍',
          })
        },
      })
    } else {
      handleAction(auth)
    }
  }

  const handleSaveDiscount = () => {
    const handleAction = async (userAuth: UserAuthType) => {
      if (!isSaved) {
        logSMEvent('DISCOUNT_SAVED', {
          id: discount.id,
          url: discount.url,
          mainTagV3: discount.mainTagV3,
          club: discount.club,
        })
      }
      setIsSaved(!isSaved)
      handleOpenSnackBar({
        message: isSaved
          ? 'El descuento ha sido eliminado de tus guardados.'
          : 'Descuento guardado en tus favoritos.',
      })
      const response = await request(
        'save_discount',
        {
          method: 'POST',
          body: JSON.stringify({
            discountId: discount.id,
            save: !isSaved,
          }),
        },
        userAuth
      )
      const newListOfDiscounts = (response?.data?.savedDiscounts as string[]) || null
      setAuth({
        ...userAuth,
        savedDiscounts: newListOfDiscounts ? newListOfDiscounts : userAuth.savedDiscounts,
      })
    }
    if (!auth || !isAuthAllowedToSaveDiscounts(auth)) {
      handleOpenLoginDrawer({
        source: 'save_button', // antes era 'like_button'
        title: 'Completa tu perfil para guardar descuentos',
        subtitle:
          'Podrás guardar descuentos, reservar mesas, acceder a beneficios exclusivos y más.',
        fields: SAVE_DISCOUNTS_FIELDS_REQUIRED,
        buttonLabel: 'Guardar descuento',
        onSubmit: handleAction,
      })
    } else {
      handleAction(auth)
    }
  }

  const discountsCommentsError = discount?.comments?.filter((c) => c.commentType === 'ERROR') || []

  return (
    <SMHorizontalDiv
      fullWidth
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'flex',
        flexWrap: 'nowrap',
        width: '100%',
      }}
    >
      {(discount.CTA || discount.cupon) && (
        <CTAButton discount={discount} source={CTAButtonSource.CARD_FOOTER} />
      )}
      {discount?.gMapsResults?.some((r) => r.reservationInfo) ? (
        <BookTableButton discount={discount} source={CTAButtonSource.CARD_FOOTER} />
      ) : null}
      {discountsCommentsError.length > 0 && (
        <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
          <AnnouncementRoundedIcon sx={{ width: '18px' }} htmlColor={neutral50White} />
          <SMP>
            {discountsCommentsError.length} Error
            {discountsCommentsError.length > 1 ? 'es' : ''}
          </SMP>
        </div>
      )}

      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'flex-end',
        }}
      >
        <SMIconButton onClick={handleLikeDiscount}>
          {isLike ? (
            <FavoriteRoundedIcon sx={{ width: '18px' }} htmlColor={neutral50White} />
          ) : (
            <FavoriteBorderRoundedIcon sx={{ width: '18px' }} htmlColor={neutral50White} />
          )}
        </SMIconButton>
        <SMIconButton onClick={handleSaveDiscount}>
          {isSaved ? (
            <BookmarkRoundedIcon sx={{ color: neutral50White, width: '18px' }} />
          ) : (
            <BookmarkBorderRoundedIcon sx={{ color: neutral50White, width: '18px' }} />
          )}
        </SMIconButton>
      </div>
    </SMHorizontalDiv>
  )
}
