import React from 'react'

import {
  MAP_DISCOUNT_CARD_HEIGHT_BIG,
  MAP_DISCOUNT_CARD_HEIGHT_SMALL,
} from '../../../../consts/stylesConsts'
import { googleMapsStarColor, neutral50White } from '../../../../style'
import googleMapsIcon from '../../../../assets/googleMapsIcon.svg'
import whatsappIcon from '../../../../assets/whatsappIcon.svg'

import { DiscountMapButton } from '../../../common/SMButton'
import style from './discountMapCard.module.css'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded'
import StarHalfRoundedIcon from '@mui/icons-material/StarHalfRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'

import { addUtmParamsToUrl, normalizeDaysToText } from '../../../../helpers/others'
import { useContext } from 'react'
import { AppContext } from '../../../../contexts/AppContext'
import { GMapsResultsType } from '../../../../types/gMaps'
import { Discount } from '../../../../types/discount'
import { SMHorizontalDiv } from '../../../../SMComponents/SMView'
import { SMP } from '../../../../SMComponents/SMText'
import { CTAButton, CTAButtonSource } from '../CTAButton'
import { showTwoDecimals } from '../../../../helpers/location'
import { DiscountContext } from '../../../../contexts/DiscountContext'
import { logSMEvent } from '../../../../events/logEvent'
import { BookTableButton } from '../BookTableButton'

const {
  mapScrollableArea,
  mapDiscountCard,
  mapDiscountHeaderFrame,
  mapHeaderImage,
  mapDiscountBodyFrame,
  mapCloseCardButton,
  mapDiscountScrollableButtonFrame,
  mapDiscountButtonText,
} = style

const transformPriceLevelToPricesIcons = (priceLevel: string) => {
  if (priceLevel === 'PRICE_LEVEL_INEXPENSIVE') return <p>$5.000–10.000</p>
  if (priceLevel === 'PRICE_LEVEL_MODERATE') return <p>$15.000–20.000</p>
  if (priceLevel === 'PRICE_LEVEL_EXPENSIVE') return <p>$30.000–40.000</p>
  if (priceLevel === 'PRICE_LEVEL_VERY_EXPENSIVE') return <p>$40.000–50.000</p>
  return ''
}

export const Rating = ({ mapsResult }: { mapsResult: GMapsResultsType }) => {
  if (!mapsResult || !mapsResult.rating) return null
  const rating = mapsResult.rating
  const ratingInt = Math.floor(rating) // this is the integer part
  const ratingDec = rating - ratingInt // this is the decimal part
  const user_ratings_total = mapsResult.userRatingCount
  const stars = []
  const starSize = '16px'
  for (let i = 0; i < ratingInt; i++) {
    stars.push(
      <StarRoundedIcon key={i} style={{ color: googleMapsStarColor, fontSize: starSize }} />
    )
  }
  if (ratingDec > 0) {
    // full half has color: googleMapsStarColor, rest of the half is grey
    stars.push(
      <StarHalfRoundedIcon
        key={ratingInt}
        style={{ color: googleMapsStarColor, fontSize: starSize }}
      />
    )
  }
  for (let i = 0; i < 5 - ratingInt - (ratingDec > 0 ? 1 : 0); i++) {
    stars.push(
      <StarOutlineRoundedIcon
        key={10 + ratingInt + i}
        style={{ color: googleMapsStarColor, fontSize: starSize }}
      />
    )
  }
  return (
    <div
      style={{
        display: 'flex',
        fontSize: '14px',
        color: neutral50White,
        alignItems: 'center',
        justifyContent: 'center',
        // border: '1px solid black',
        width: 'fit-content',
      }}
    >
      <p>{rating}</p>
      &nbsp;
      {stars}
      <p>&nbsp;({user_ratings_total})&nbsp; ·&nbsp;&nbsp;</p>
      {mapsResult?.priceLevel && transformPriceLevelToPricesIcons(mapsResult.priceLevel)}
    </div>
  )
}

export function DiscountMapCard({
  isStar,
  selected,
  setSelected,
  focusMap,
  setFocusMap,
}: // approvedGMapsResults,
// removedGMapsResults,
// setApprovedGMapsResults,
// setRemovedGMapsResults,
{
  isStar: boolean
  selected: {
    discount: Discount
    gMapResult: GMapsResultsType
  }
  setSelected: any
  focusMap: boolean
  setFocusMap: any
  // approvedGMapsResults: string[]
  // removedGMapsResults: string[]
  // setApprovedGMapsResults: any
  // setRemovedGMapsResults: any
}) {
  const { clubs } = useContext(AppContext)
  const { handleDiscountClick } = useContext(DiscountContext)

  const discount = selected.discount
  const mapsResult = selected.gMapResult
  if (!discount || !mapsResult) return null

  const shareId = Math.random().toString(36).substring(2)
  const discountUrl = `www.savemoney.cl/descuentos?openDiscountId=${discount.id}&shareId=${shareId}`
  const title = discount.titulo || ''
  const tarjetaText = discount.subTarjeta
    ? `la(s) tarjeta(s): ${discount.subTarjeta} del ${discount.club}`
    : `${discount.club}`

  // Add valid days
  const validDays = normalizeDaysToText(discount.diasNormalizados)
  const validDaysText = validDays === 'Todos' ? 'Todos los días' : `Válido: ${validDays}`

  // Add expiration date if available
  const expirationText = discount.fecha_hasta ? `\n📅 Hasta: ${discount.fecha_hasta}` : ''

  // Add location if available
  const locationText = discount.XClosestLocation?.distance
    ? `\n📍 A ${showTwoDecimals(discount.XClosestLocation.distance)} km de mi`
    : ''

  const text = `
Encontré este descuento usando SaveMoney. 

*${title}*
💳 Haciendo uso de ${tarjetaText}
⏰ ${validDaysText}${expirationText}${locationText}
📍 Ubicado en: ${selected.gMapResult.formattedAddress}

Antes de dirigirte al local, asegurate de leer bien la descripción del descuento en el siguiente enlace:
👉 ${discountUrl}
`

  const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(text)}`

  const cardBody = (
    <div className={mapDiscountBodyFrame}>
      <div>
        <SMHorizontalDiv>
          {discount.club !== 'SaveMoney' && (
            <img
              src={clubs ? clubs[discount.club]?.img : ''}
              style={{ maxWidth: 30, maxHeight: 20, objectFit: 'contain' }}
              alt={discount.club}
            />
          )}
          <p className="body-1" style={{ fontSize: '17px' }}>
            {discount.titulo}
          </p>
        </SMHorizontalDiv>
        <Rating mapsResult={mapsResult} />
        {isStar ? (
          <>
            <p className="body-1" style={{ color: neutral50White }}>
              {mapsResult?.types?.join(', ')}
            </p>
          </>
        ) : (
          <>
            <SMHorizontalDiv>
              <p className="body-1" style={{ fontSize: '16px' }}>
                {discount?.club}&nbsp;&nbsp;-
              </p>
              <SMP>
                {discount.subTarjeta
                  .split('; ')
                  .map((stId) => {
                    return (
                      clubs && clubs[discount.club]?.subTarjetas?.find((st) => st.id == stId)?.label
                    )
                  })
                  .join(', ')}
              </SMP>
            </SMHorizontalDiv>

            <p className="body-1" style={{ color: neutral50White }}>
              {normalizeDaysToText(discount.diasNormalizados)}
              &nbsp;&nbsp;-&nbsp;&nbsp; Válido hasta: {discount?.fecha_hasta}
            </p>
          </>
        )}
      </div>
      <div className={mapDiscountScrollableButtonFrame}>
        {/* <StarButtons
          isStar={isStar}
          selected={selected}
          approvedGMapsResults={approvedGMapsResults}
          removedGMapsResults={removedGMapsResults}
          setApprovedGMapsResults={setApprovedGMapsResults}
          setRemovedGMapsResults={setRemovedGMapsResults}
        /> */}
        {(discount.CTA || discount.cupon) && (
          <>
            <CTAButton discount={discount} source={CTAButtonSource.MAP_CARD} />
            <div />
          </>
        )}
        {discount?.gMapsResults?.some((r) => r.reservationInfo) ? (
          <BookTableButton discount={discount} source={CTAButtonSource.MAP_CARD} />
        ) : null}
        {discount.club !== 'SaveMoney' && !discount.cupon && (
          <DiscountMapButton
            buttonname={'maps_go_to_discount_button'}
            url={addUtmParamsToUrl(discount)}
            onClick={(e: any) => {
              e.stopPropagation()
            }}
          >
            {clubs && (
              <img
                src={clubs[discount.club]?.img}
                style={{ width: (clubs[discount.club]?.width || 45) - 8 }}
                alt="."
              />
            )}
            <p className={mapDiscountButtonText}>Ir al sitio</p>
          </DiscountMapButton>
        )}
        <DiscountMapButton
          buttonname={'maps_share_button'}
          url={whatsappUrl}
          onClick={(e: any) => {
            e.stopPropagation()
          }}
        >
          <img
            src={whatsappIcon}
            alt="whatsappIcon"
            style={{
              height: '26px',
            }}
          />
          <p className={mapDiscountButtonText}>Compartir</p>
        </DiscountMapButton>
        <DiscountMapButton
          buttonname={'maps_go_to_maps_button'}
          url={`https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${mapsResult?.id}`}
          onClick={(e: any) => {
            e.stopPropagation()
          }}
        >
          <img
            src={googleMapsIcon}
            alt="gmapsIcon"
            style={{
              height: '20px',
            }}
          />
          <p className={mapDiscountButtonText}>Abrir maps</p>
        </DiscountMapButton>
        <DiscountMapButton
          buttonname={'maps_see_more_button'}
          url={'#'}
          onClick={(e: any) => {
            // Prevent default behavior to avoid any navigation
            e.preventDefault()
            // Stop propagation to prevent other handlers from firing
            e.stopPropagation()

            // Log the event
            logSMEvent('MAP_SEE_MORE_BUTTON_CLICKED', {
              id: discount.id,
              title: discount.titulo,
              club: discount.club,
              mainTagV3: discount.mainTagV3,
            })

            // Use handleDiscountClick to open the DiscountDrawer
            handleDiscountClick(discount)
          }}
        >
          <MoreHorizRoundedIcon
            style={{
              height: '20px',
            }}
          />
          <p className={mapDiscountButtonText}>Ver más</p>
        </DiscountMapButton>
      </div>
    </div>
  )
  return (
    <div
      className={mapScrollableArea}
      style={{
        // transition: 'transform 0.3s ease-in-out',
        // transform,
        height: focusMap ? MAP_DISCOUNT_CARD_HEIGHT_SMALL : MAP_DISCOUNT_CARD_HEIGHT_BIG,
        transition: 'height 0.5s ease-in-out',
      }}
      onClick={() => {
        setFocusMap(!focusMap)
      }}
    >
      <div className={mapDiscountCard}>
        <div className={mapDiscountHeaderFrame}>
          <div className={mapCloseCardButton}>
            <CloseRoundedIcon
              sx={{
                color: 'white',
                height: '14px',
                cursor: 'pointer',
              }}
              onClick={() => {
                return setSelected(null)
              }}
            />
          </div>
          <img className={mapHeaderImage} src={discount.bgimage} alt="." />
          {discount.logo && <img className={mapHeaderImage} alt="." src={discount.logo} />}
          {mapsResult?.photosUrls?.map((url) => (
            <img
              key={url}
              className={mapHeaderImage}
              style={{ minWidth: '120px' }}
              src={url}
              alt="."
            />
          ))}
        </div>
        {!focusMap && cardBody}
      </div>
    </div>
  )
}
