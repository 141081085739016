import React, { useState, useEffect, useContext } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { AppContext, UserAuthType } from '../../../../contexts/AppContext'
import ButtonTracked from '../../../common/ButtonTracked'
import { SMMuiTelInput, SMTextField } from '../../../../SMComponents/SMInput'
import { formatRut, isValidPhoneNumber, isValidRut } from '../../../../helpers/validateForms'
import { request } from '../../../../helpers/request'
import { logSMEvent } from '../../../../events/logEvent'
import { Checkbox, FormControlLabel, Box } from '@mui/material'
import Link from '@mui/material/Link'
import { appVersion } from '../../../../consts/version'
import { CircularProgress, Button } from '@mui/material'
import { AccountCircle } from '@mui/icons-material'

export const LoginDialog = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: (newState: any) => void
}) => {
  const { auth, setAuth, handleOpenSnackBar, user, filtersSelected } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)
  const [rut, setRut] = useState((auth && auth?.rut) || '')
  const [phoneNumber, setPhoneNumber] = useState<string>(
    auth && auth?.phoneNumber ? auth?.phoneNumber : '+56'
  )
  const [termsAccepted, setTermsAccepted] = useState(false)
  const includePhoneNumber = !(auth && auth?.phoneNumber)
  const includeRut = !(auth && auth?.rut)

  useEffect(() => {
    // auth puede que se cargue más lento que el componente
    if (auth && auth?.rut) setRut(auth.rut)
    if (auth && auth?.phoneNumber) setPhoneNumber(auth.phoneNumber)
  }, [auth])

  useEffect(() => {
    if (open) {
      logSMEvent('OPEN_LOGIN_DIALOG', {
        auth,
        appVersion,
      })
    }
  }, [open])

  const handleRutChange = (e: any) => {
    const rut = formatRut(e.target.value)
    setRut(rut)
  }

  const handleButtonClick = async () => {
    setIsLoading(true)
    request('create_user', {
      method: 'POST',
      body: JSON.stringify({
        rut,
        phoneNumber,
        userFrontendRandomId: user,
        source: 'login_dialog',
        firstFiltersSelected: filtersSelected,
      }),
    }).then((response) => {
      const responseAuth = response.data as UserAuthType
      setAuth(responseAuth)
    })
    logSMEvent('SUCCESS_CLOSE_LOGIN_DIALOG', { appVersion })
    handleOpenSnackBar({
      message: '¡Cuenta creada con éxito! Descubre y guarda tus descuentos favoritos',
      duration: 3000,
    })
    setOpen(false)
    setIsLoading(false)
  }

  const isValidForm = () => {
    if (includeRut && !isValidRut(rut)) return false
    if (includePhoneNumber && !isValidPhoneNumber(phoneNumber)) return false
    if (!termsAccepted) return false
    return true
  }

  const handlePhoneNumberChange = (newValue: string) => {
    setPhoneNumber(newValue)
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        logSMEvent('CLOSE_LOGIN_DIALOG_CLICK_OUTSIDE', { appVersion })
        setOpen(false)
      }}
      PaperProps={{
        component: 'form',
        sx: {
          width: '100%',
          maxWidth: { xs: '100%', sm: '450px' },
          margin: { xs: '16px', sm: '32px' },
          borderRadius: { xs: '12px', sm: '16px' },
        },
      }}
      fullScreen={false}
      fullWidth
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '1.5rem',
          fontWeight: 600,
          color: 'primary.main',
          pt: 3,
          pb: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <AccountCircle
            sx={{
              fontSize: 64,
              color: 'primary.main',
              mb: 2,
              opacity: 0.9,
            }}
          />
          Bienvenido a SaveMoney
        </Box>
      </DialogTitle>

      <DialogContent sx={{ px: { xs: 3, sm: 4 }, py: 2 }}>
        <DialogContentText
          sx={{
            textAlign: 'center',
            mb: 4,
            color: 'text.secondary',
            fontSize: '0.95rem',
          }}
        >
          Crea tu cuenta para guardar tus tarjetas y descuentos favoritos
        </DialogContentText>

        {includeRut && (
          <SMTextField
            margin="dense"
            sx={{
              mb: 2.5,
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
              },
            }}
            autoFocus
            validateOnBlur={isValidRut}
            textOnBlurError="Rut inválido"
            type="numeric"
            label="Rut"
            value={rut}
            onChange={handleRutChange}
            fullWidth
          />
        )}

        {includePhoneNumber && (
          <SMMuiTelInput
            sx={{
              mb: 2.5,
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
              },
            }}
            validateOnBlur={isValidPhoneNumber}
            textOnBlurError="Número inválido"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            fullWidth
          />
        )}

        <FormControlLabel
          control={
            <Checkbox
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
              name="terms"
              color="primary"
            />
          }
          sx={{
            mt: 1,
            mb: 2,
            '& .MuiTypography-root': {
              fontSize: '0.85rem',
            },
          }}
          label={
            <Box
              sx={{
                color: 'text.secondary',
                fontSize: '0.8rem',
                lineHeight: 1.5,
              }}
            >
              Al crear una cuenta, confirmo que he leído y acepto los{' '}
              <Link
                href="https://www.savemoney.cl/terminos.html"
                target="_blank"
                rel="noopener"
                sx={{
                  fontWeight: 500,
                  textDecorationColor: 'primary.main',
                  '&:hover': {
                    textDecorationColor: 'primary.dark',
                  },
                }}
              >
                términos y condiciones
              </Link>{' '}
              de uso y la política de privacidad
            </Box>
          }
        />
      </DialogContent>

      <DialogActions
        sx={{
          flexDirection: 'column',
          px: 3,
          pb: 3,
        }}
      >
        <ButtonTracked
          disabled={!isValidForm() || isLoading}
          buttonname={`login_dialog_2`}
          variant="contained"
          onClick={() => handleButtonClick()}
          fullWidth
          sx={{
            fontWeight: 600,
            textTransform: 'none',
            py: 1.5,
            fontSize: '1rem',
            borderRadius: '10px',
            boxShadow: 2,
          }}
        >
          {isLoading ? (
            <>
              <CircularProgress size={20} color="inherit" sx={{ mr: 1 }} />
              Cargando...
            </>
          ) : (
            'Crear cuenta'
          )}
        </ButtonTracked>

        <Button
          onClick={() => {
            logSMEvent('CLOSE_LOGIN_DIALOG_CLICK_CANCEL', { appVersion })
            setOpen(false)
          }}
          sx={{
            mt: 1,
            color: 'text.secondary',
            textTransform: 'none',
          }}
        >
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
