import React, { useContext, useEffect, useState } from 'react'
import { Discount } from '../../../../types/discount'
import { neutralWhite } from '../../../../style'
import { DAYS_ORDER } from '../../../../helpers/others'
import ButtonTracked from '../../../common/ButtonTracked'
import { AppContext } from '../../../../contexts/AppContext'
import {
  ALL_DAYS,
  DaysEnums,
  FilterOptionsEnum,
  mapOptions,
} from '../../../../consts/filterOptions'
import { Switch } from '@mui/material'
import { SMP } from '../../../../SMComponents/SMText'
import { MAINTAGV3Enum } from '../../../../types/mainTagV3'
import { DiscountPanel } from './DiscountPanel'
import { DiscountContext } from '../../../../contexts/DiscountContext'

export const MAX_PANEL_WIDTH = 350

// import { Rating } from '../map/DiscountMapCard'

// const getAvgRating = (discount: Discount) => {
//   if (!discount.gMapsResults || discount.gMapsResults.length === 0) return 0
//   const results = discount.gMapsResults.filter((r) => !!r.rating)
//   const rating = results.reduce((acc, r) => acc + (r.rating || 0), 0) / results.length // || 0 not possible
//   return rating.toFixed(1)
// }

const createPanelsAndAssignDiscounts = (
  discountsByClub: {
    [key: string]: Discount[]
  },
  amountOfPanels: number
): Discount[][] => {
  const panelLength = (panel: Discount[]) => {
    let sum = 0
    panel.forEach((discount) => {
      if (discount.club === 'Santander') {
        sum += 0.1 // esto es xq el santander tiene muchas sub-tarjetas
      }
      if (discount.club === 'BICE') {
        sum += 0.2
      }
      if (discount.club === 'Scotiabank') {
        sum += 0.1
      }
      if (discount.club === 'CMR') {
        sum += 0.9
      }
      sum += 1
    })
    return sum
  }
  const panels: Discount[][] = []
  for (let i = 0; i < amountOfPanels; i++) {
    panels.push([])
  }
  Object.keys(discountsByClub)
    .sort((a, b) => discountsByClub[b].length - discountsByClub[a].length)
    .forEach((club) => {
      const shortesPanelIndex = panels.reduce(
        (acc, panel, index) => (panelLength(panel) < panelLength(panels[acc]) ? index : acc),
        0
      )
      panels[shortesPanelIndex].push(...discountsByClub[club])
    })
  return panels.filter((panel) => panel.length > 0)
}

const calculateFontSizeBasedOnScreenWidth = (screenWidth: number) => {
  const minFontSize = 14
  const maxFontSize = 14
  const fontSize = screenWidth / 100
  return Math.min(Math.max(minFontSize, fontSize), maxFontSize)
}

const DiscountsTableWithMultiplePanelsForCategory = ({
  isStar,
  discountsToShow,
}: {
  isStar: boolean
  discountsToShow: Discount[]
}) => {
  const { restaurantList } = useContext(DiscountContext)
  const { filtersSelected, setFiltersSelected } = useContext(AppContext)
  const [filterDiscountsNotInRestaurantList, setFilterDiscountsNotInRestaurantList] =
    useState(false)
  const [adjustableFontSize, setAdjustableFontSize] = useState(
    calculateFontSizeBasedOnScreenWidth(window.innerWidth)
  )
  const [amountOfPanels, setAmounOfPanels] = useState(
    Math.max(1, Math.floor(window.innerWidth / MAX_PANEL_WIDTH))
  )

  useEffect(() => {
    const handleResize = () => {
      setAdjustableFontSize(calculateFontSizeBasedOnScreenWidth(window.innerWidth))
      setAmounOfPanels(Math.max(1, Math.floor(window.innerWidth / MAX_PANEL_WIDTH)))
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // AGRUPAR POR DÍA
  // si un dcto aparece con L,W  => lo llevamos a dos descuentos disintos uno con L y otro con W
  const discountsWithRepeatedByDay: Discount[] = []
  discountsToShow
    .filter((d) => {
      // filtro para ver temas de reserva
      if (!isStar) return true
      if (!filterDiscountsNotInRestaurantList) return true
      return (
        !restaurantList.map((s) => s.slug).includes(d.local) &&
        d.gMapsResults.some((r) => r.reservable)
      )
    })
    .forEach((discount) => {
      const discountDays = discount?.diasNormalizados
        ? discount?.diasNormalizados.split(',')
        : ALL_DAYS
      if (
        ((discountDays.length == ALL_DAYS.length || discountDays.length == 0) &&
          // discount.mainTagV3?.includes(MAINTAGV3Enum.RESTOBAR_PRESENCIAL)
          !discount.mainTagV3?.includes(MAINTAGV3Enum.RESTOBAR_PRESENCIAL)) ||
        isStar
      ) {
        discountsWithRepeatedByDay.push(discount) // para cuando es 'Todos' y no es restobar
      } else {
        discountDays.forEach((day) => {
          if (
            filtersSelected[FilterOptionsEnum.DIAS].length === 0 ||
            filtersSelected[FilterOptionsEnum.DIAS].includes(day as DaysEnums)
          ) {
            discountsWithRepeatedByDay.push({ ...discount, diasNormalizados: day })
          }
        })
      }
    })

  const firstAllDays = (a: Discount, b: Discount) => {
    const lengthA = a.diasNormalizados.split(',').length
    const lengthB = b.diasNormalizados.split(',').length
    const allDaysA = lengthA == ALL_DAYS.length || lengthA == 0
    const allDaysB = lengthB == ALL_DAYS.length || lengthB == 0
    if (allDaysA && allDaysB) return 0
    if (allDaysA) return -1
    if (allDaysB) return 1
    return 0
  }

  const compareSubTarjeta = (a: Discount, b: Discount) => {
    if (a.subTarjeta && b.subTarjeta && a.subTarjeta !== a.club && b.subTarjeta !== b.club) {
      return a.subTarjeta.localeCompare(b.subTarjeta)
    }
    return 0
  }

  // se ordena dps de hacer el split por día dado que se ordenará por día.
  const discountsSort = discountsWithRepeatedByDay.sort(
    (a, b) =>
      a.club?.localeCompare(b.club) ||
      compareSubTarjeta(a, b) ||
      firstAllDays(a, b) ||
      a.diasNormalizados.length - b.diasNormalizados.length || // para cuando es 'Todos'
      (a.diasNormalizados &&
        b.diasNormalizados &&
        DAYS_ORDER.indexOf(a.diasNormalizados[0]) - DAYS_ORDER.indexOf(b.diasNormalizados[0])) ||
      a.diasNormalizados?.localeCompare(b.diasNormalizados) ||
      parseInt(b.cantidadDescuento) - parseInt(a.cantidadDescuento)
  )

  const discountsByClub: { [key: string]: Discount[] } = {}

  discountsSort.forEach((discount) => {
    if (!discountsByClub[discount.club]) discountsByClub[discount.club] = []
    discountsByClub[discount.club].push(discount)
  })
  const panels = createPanelsAndAssignDiscounts(discountsByClub, amountOfPanels)

  return (
    <>
      {isStar && (
        <div style={{ display: 'flex', gap: 10, marginLeft: 20 }}>
          <ButtonTracked
            buttonname="ver_cadenas_button"
            onClick={() => {
              setFiltersSelected({
                ...filtersSelected,
                Mapa: filtersSelected.Mapa.includes(mapOptions.INCLUIR_FRANQUICIAS)
                  ? []
                  : [mapOptions.INCLUIR_FRANQUICIAS],
              })
            }}
            variant="outlined"
            disableRipple={false} // enable wave effect
            style={{
              borderRadius: '10px',
              borderColor: neutralWhite,
              backgroundColor: neutralWhite,
              textTransform: 'none',
              padding: '2px 12px',
              boxShadow: 'rgba(0, 0, 0, 0.44) 0px 3px 10px',
            }}
          >
            <SMP>Ver cadenas</SMP>
            <Switch checked={filtersSelected.Mapa.includes(mapOptions.INCLUIR_FRANQUICIAS)} />
          </ButtonTracked>
          <ButtonTracked
            buttonname="ver_gMapsResults_button"
            onClick={() => {
              setFilterDiscountsNotInRestaurantList(!filterDiscountsNotInRestaurantList)
            }}
            variant="outlined"
            disableRipple={false} // enable wave effect
            style={{
              borderRadius: '10px',
              borderColor: neutralWhite,
              backgroundColor: neutralWhite,
              textTransform: 'none',
              padding: '2px 12px',
              boxShadow: 'rgba(0, 0, 0, 0.44) 0px 3px 10px',
            }}
          >
            <SMP>No en lista de restaurantes (TODO)</SMP>
            {/* fix logic, by slugToId, not just slug or using localId */}
            <Switch checked={filterDiscountsNotInRestaurantList} />
          </ButtonTracked>
        </div>
      )}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center', // en caso de haber 1 sola tabla
            alignItems: 'flex-start',
            gap: 10,
            fontSize: adjustableFontSize,
          }}
        >
          {panels.map((panel, index) => {
            return <DiscountPanel key={index.toString()} panel={panel} isStar={isStar} />
          })}
        </div>
      </div>
    </>
  )
}

export const DiscountsTableWithMultiplePanels = ({
  isStar,
  discountsToShow,
}: {
  isStar: boolean
  discountsToShow: Discount[]
}) => {
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 40 }}>
          <DiscountsTableWithMultiplePanelsForCategory
            isStar={isStar}
            discountsToShow={discountsToShow}
          />
        </div>
      </div>
    </>
  )
}
