import './intro.css'
import '../../../App.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { Switch } from '@mui/material'
import React, { useContext } from 'react'
import { IIntro, INTRO_DAYS_PAGE_NAME, SHOW_SEE_DISCOUNTS_BUTTON } from './IIntro'
import { DayCheckBox } from '../components/DayCheckBox'
import { AppContext } from '../../../contexts/AppContext'
import { handleSwitchState } from '../../../helpers/handleFilterSelection'
import { logSMEvent } from '../../../events/logEvent'
import { SMH2 } from '../../../SMComponents/SMText'
import { useFilterOptions } from '../../../hooks/filterOptions'
import { SMHorizontalDiv } from '../../../SMComponents/SMView'
import { BackButton } from '../components/BackButton'

export const SelectDays = function () {
  const navigate = useNavigate()
  const filterLabel = 'Dias'
  const { filtersSelected, setFiltersSelected } = useContext(AppContext)
  const filterOptions = useFilterOptions()
  const location = useLocation()
  const showSeeDiscountButton = location.state?.options?.showSeeDiscountButton
  return (
    <IIntro
      pageName={INTRO_DAYS_PAGE_NAME}
      onClickNext={() => {
        logSMEvent('CLICK_NEXT_IN_DAYS', {
          days: filtersSelected.Dias.join(','),
        })
        return navigate('/location', SHOW_SEE_DISCOUNTS_BUTTON(showSeeDiscountButton))
      }}
    >
      <SMHorizontalDiv maxSpaceBetween>
        <BackButton
          onClickBack={() => {
            logSMEvent('CLICK_BACK_IN_DAYS')
            return navigate('/clubs', SHOW_SEE_DISCOUNTS_BUTTON(showSeeDiscountButton))
          }}
        />
        <SMH2 center>¿Para qué días de la semana?</SMH2>
        <div style={{ width: 30 }} />
      </SMHorizontalDiv>

      <div className={'body-days'}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            maxWidth: '325px',
            width: '100%',
          }}
        >
          <p>Seleccionar todos</p>
          <Switch
            checked={filtersSelected[filterLabel]?.length === filterOptions[filterLabel]?.length}
            onClick={() => {
              handleSwitchState(filterLabel, filtersSelected, setFiltersSelected, filterOptions)
            }}
          />
        </div>
        {filterOptions.Dias.map((day) => {
          return <DayCheckBox key={day} day={day} />
        })}
      </div>
    </IIntro>
  )
}
