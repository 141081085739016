import { createContext } from 'react'
import { DEFAULT_FILTERS, FilterOptionsType } from '../consts/filterOptions'
import { UserLocation } from '../types/main'
import { ClubEnums, ClubsTypeDB } from '../types/clubs'
import { Ad } from '../types/ads'
import { Timestamp } from 'firebase/firestore'

export type OpenSnackBarProps = {
  message: string
  duration?: number
  type?: 'success' | 'error' | 'warning' | 'info'
}

export const defaultSnackBarProps: OpenSnackBarProps = {
  message: '',
}

export type ActiveGeneratedCuponInUser = {
  id?: string
  discountId?: string
  discountTitle?: string
  discountLogo?: string
  cuponQRLink?: string
  generatedAt?: Timestamp
  expiresAt?: Timestamp
}

export type UserAuthType = {
  id: string
  secretToken: string
  rut: string
  name?: string
  email?: string
  phoneNumber?: string
  userType?: string
  username?: string
  savedDiscounts?: string[]
  likedDiscounts?: string[]
  role?: string
  createdAt?: string
  referralCode?: string
  referredTo?: string[]
  activeGeneratedCupons?: ActiveGeneratedCuponInUser[]
}

export type ClubsType = {
  [key in ClubEnums]: ClubsTypeDB
}

interface AppContextType {
  user: string
  amountOfVisits: number
  clubs: ClubsType | null
  filtersSelected: FilterOptionsType
  setFiltersSelected: (filters: FilterOptionsType) => void
  usersLocation: UserLocation | null
  setUsersLocation: (location: UserLocation) => void
  auth: UserAuthType | null | false // null means loading, false means not logged in
  setAuth: (auth: UserAuthType | null | false) => void
  openSnackBar: boolean
  handleOpenSnackBar: (props: OpenSnackBarProps) => void // TODO: ESTO AL PARECER TIENE UN BUG PORQUE HACE QUE TODO SE REENDERE VARIAS VECES
  setNavButtonClicked: (name: string | false) => void
  openLoginDialog: boolean
  setOpenLoginDialog: (newState: boolean) => void
  ads: Ad[] | null // null means loading
  displayedAds: Set<string>
  addDispleyedAds: (id: string) => void
}

export const AppContext = createContext<AppContextType>({
  displayedAds: new Set(),
  addDispleyedAds: () => {},
  user: '',
  amountOfVisits: 0,
  clubs: null,
  filtersSelected: DEFAULT_FILTERS,
  setFiltersSelected: () => {},
  usersLocation: null,
  setUsersLocation: () => {},
  auth: null,
  setAuth: () => {},
  openSnackBar: false,
  handleOpenSnackBar: () => {},
  setNavButtonClicked: () => {},
  openLoginDialog: false,
  setOpenLoginDialog: () => {},
  ads: [],
})
