import React, { useState, useEffect, useContext } from 'react'
import { primaryPrimary, neutral70White } from '../../../style'
import { logSMEvent } from '../../../events/logEvent'
import { ReactComponent as InstagramIcon } from '../../../assets/instagramIcon.svg'
import SaveMoneyLogo from '../../../assets/logo/logoTransparentBackground.png'
import { CircularProgress, Box, Typography, Container } from '@mui/material'
import { motion, AnimatePresence } from 'framer-motion'
import { AppContext } from '../../../contexts/AppContext'
import { ClubEnums } from '../../../types/clubs'

const clubsHardcoded = [
  'Bci',
  'BICE',
  'Banco Estado',
  'Banco Falabella',
  'Banco Ripley',
  'Santander',
  'Security',
  'Scotiabank',
  'Itaú',
  'Chek',
  'Banco De Chile',
  'Full Copec',
  'MACH',
  'Entel',
  'Vecino Las Condes',
  'La Reina',
  'La Tercera',
  'El Mercurio',
  'Otros...',
]

export const LoadingDiscountsScreen = () => {
  const { clubs, filtersSelected } = useContext(AppContext)
  const [messageToShowWhileLoading, setMessageToShowWhileLoading] = useState<string>('')

  // Get ordered club names based on selection and availability
  const getOrderedClubNames = () => {
    if (!clubs) return clubsHardcoded

    const selectedClubs = filtersSelected.Tarjetas || []
    const allClubIds: ClubEnums[] = Object.keys(clubs) as ClubEnums[]

    // Sort clubs: selected first, then remaining clubs
    const orderedClubIds = [
      ...selectedClubs,
      ...allClubIds.filter((id) => !selectedClubs.includes(id)),
    ]

    return orderedClubIds.map((clubId) => clubs[clubId]?.label || clubId)
  }

  useEffect(() => {
    const clubNames = getOrderedClubNames()
    setMessageToShowWhileLoading(clubNames[0])

    const interval = setInterval(() => {
      setMessageToShowWhileLoading((currentMessage) => {
        const currentIndex = clubNames.indexOf(currentMessage)
        return clubNames[Math.min(currentIndex + 1, clubNames.length - 1)]
      })
    }, 1200)

    return () => clearInterval(interval)
  }, [clubs, filtersSelected.Tarjetas])

  return (
    <Container
      maxWidth="sm"
      sx={{
        backgroundColor: 'white',
        minHeight: '100vh',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: { xs: 2, sm: 4 },
          textAlign: 'center',
          paddingBottom: 10,
        }}
      >
        {/* Logo */}
        <Box sx={{ mt: 8, mb: 8 }}>
          <img
            src={SaveMoneyLogo}
            alt="App Logo"
            style={{
              width: 90,
              height: 90,
              objectFit: 'contain',
            }}
          />
        </Box>

        {/* Loading Section */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 5,
          }}
        >
          <CircularProgress
            size={60}
            thickness={4}
            sx={{
              color: primaryPrimary,
            }}
          />

          {/* Static Text and Animated Club Name */}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography
              variant="body1"
              sx={{
                color: neutral70White,
                fontWeight: 500,
                fontSize: '1rem',
              }}
            >
              Buscando los mejores descuentos en
            </Typography>

            <AnimatePresence mode="wait">
              <motion.div
                key={messageToShowWhileLoading}
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -20, opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: primaryPrimary,
                    fontWeight: 600,
                    mt: 3,
                  }}
                >
                  {messageToShowWhileLoading}
                </Typography>
              </motion.div>
            </AnimatePresence>
          </Box>
        </Box>

        {/* Social Footer */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'white',
            padding: 3,
            justifyContent: 'center',
            borderTop: '1px solid rgba(0, 0, 0, 0.05)',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: neutral70White,
              fontWeight: 500,
            }}
          >
            Síguenos en
          </Typography>
          <motion.a
            href="https://www.instagram.com/savemoney.cl/"
            target="_blank"
            rel="noreferrer"
            onClick={() => logSMEvent('CLICK_FOLLOW_US', { page: 'table_loading' })}
            whileHover={{ scale: 1.05 }}
            style={{
              display: 'flex',
              alignItems: 'center',
              color: primaryPrimary,
              textDecoration: 'none',
              fontWeight: 600,
            }}
          >
            @SaveMoney.cl
            <InstagramIcon
              style={{
                marginLeft: 4,
                fill: primaryPrimary,
                height: 24,
              }}
            />
          </motion.a>
        </Box>
      </Box>
    </Container>
  )
}
