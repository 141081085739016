import React, { useRef, useEffect, useContext } from 'react'
import {
  ALLMAINTAGSV3,
  MAINTAGV3Enum,
  MAINTAGV3Icons,
  MAINTAGV3Label,
} from '../../../types/mainTagV3'
import { primaryPrimary, neutralWhite, neutralBlack } from '../../../style'
import { logSMEvent } from '../../../events/logEvent'
import { FilterOptionsEnum } from '../../../consts/filterOptions'
import { AppContext } from '../../../contexts/AppContext'

export const MainTagV3Selector = () => {
  const { filtersSelected, setFiltersSelected } = useContext(AppContext)
  const [hoveredCategory, setHoveredCategory] = React.useState<MAINTAGV3Enum | null>(null)
  const [isAllCategoriesHovered, setIsAllCategoriesHovered] = React.useState(false)
  const [containerWidth, setContainerWidth] = React.useState(0)
  const [contentWidth, setContentWidth] = React.useState(0)
  const containerRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const categoryRefs = useRef<Record<MAINTAGV3Enum, HTMLDivElement | null>>(
    {} as Record<MAINTAGV3Enum, HTMLDivElement | null>
  )

  // Calculate if content fits within container
  const contentFits = containerWidth >= contentWidth

  // Measure container and content widths
  useEffect(() => {
    if (containerRef.current && contentRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.target === containerRef.current) {
            setContainerWidth(entry.contentRect.width)
          } else if (entry.target === contentRef.current) {
            setContentWidth(entry.contentRect.width)
          }
        }
      })

      resizeObserver.observe(containerRef.current)
      resizeObserver.observe(contentRef.current)

      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [])

  // Scroll selected category into view when component mounts or selection changes
  useEffect(() => {
    const selectedCategory = filtersSelected[FilterOptionsEnum.MAINTAGV3][0]
    if (selectedCategory && categoryRefs.current[selectedCategory] && containerRef.current) {
      const container = containerRef.current
      const element = categoryRefs.current[selectedCategory]

      // Check if element is not null before accessing its properties
      if (element) {
        // Calculate position to scroll to (center the element in the container)
        const elementRect = element.getBoundingClientRect()
        const containerRect = container.getBoundingClientRect()

        const elementCenter = elementRect.left + elementRect.width / 2
        const containerCenter = containerRect.left + containerRect.width / 2
        const scrollAmount = elementCenter - containerCenter + container.scrollLeft

        // Smooth scroll to the element
        container.scrollTo({
          left: scrollAmount,
          behavior: 'smooth',
        })
      }
    }
  }, [filtersSelected[FilterOptionsEnum.MAINTAGV3]])

  // Add helper to check if all categories are selected
  const areAllCategoriesSelected = () => {
    return filtersSelected[FilterOptionsEnum.MAINTAGV3].length === ALLMAINTAGSV3.length
  }

  const handleCategoryClick = (category: MAINTAGV3Enum) => {
    setFiltersSelected({
      ...filtersSelected,
      [FilterOptionsEnum.MAINTAGV3]: [category],
    })
    logSMEvent('CLICK_CATEGORY_SELECTOR', { category })
  }

  // Add handler for "all categories" click
  const handleAllCategoriesClick = () => {
    setFiltersSelected({
      ...filtersSelected,
      [FilterOptionsEnum.MAINTAGV3]: [...ALLMAINTAGSV3],
    })
    logSMEvent('CLICK_CATEGORY_SELECTOR', { category: 'all' })
  }

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        marginBottom: '6px',
        marginTop: '6px',
        backgroundColor: 'transparent',
      }}
    >
      <div
        ref={containerRef}
        style={{
          overflowX: 'auto',
          display: 'flex',
          padding: '6px 16px',
          WebkitOverflowScrolling: 'touch',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          backdropFilter: 'none',
          backgroundColor: 'transparent',
          justifyContent: contentFits ? 'center' : 'flex-start',
          maxWidth: '100%',
        }}
        className="hide-scrollbar"
      >
        <div
          ref={contentRef}
          style={{
            display: 'flex',
            gap: 10,
            flexWrap: 'nowrap',
            backgroundColor: 'transparent',
          }}
        >
          {/* Add "Todas las categorías" bubble */}
          <div
            onClick={handleAllCategoriesClick}
            onMouseEnter={() => setIsAllCategoriesHovered(true)}
            onMouseLeave={() => setIsAllCategoriesHovered(false)}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              cursor: 'pointer',
              minWidth: 56,
              maxWidth: 56,
              textAlign: 'center',
              transition: 'transform 0.2s ease-out',
              transform: areAllCategoriesSelected()
                ? 'scale(1.03) translateY(-2px)'
                : isAllCategoriesHovered
                ? 'scale(1.02) translateY(-1px)'
                : 'scale(1) translateY(0)',
              flexShrink: 0,
              backgroundColor: 'transparent',
            }}
          >
            <div
              style={{
                width: 48,
                height: 48,
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: areAllCategoriesSelected()
                  ? primaryPrimary
                  : isAllCategoriesHovered
                  ? 'rgba(255, 255, 255, 1)'
                  : 'rgba(255, 255, 255, 0.9)',
                boxShadow: areAllCategoriesSelected()
                  ? '0px 4px 8px rgba(66, 99, 235, 0.18)'
                  : isAllCategoriesHovered
                  ? '0px 4px 6px rgba(0, 0, 0, 0.08)'
                  : '0px 2px 4px rgba(0, 0, 0, 0.05)',
                transition: 'all 0.25s cubic-bezier(0.4, 0, 0.2, 1)',
                marginBottom: 3,
                border: areAllCategoriesSelected()
                  ? '1px solid transparent'
                  : isAllCategoriesHovered
                  ? '1px solid rgba(66, 99, 235, 0.15)'
                  : '1px solid rgba(230, 232, 240, 0.6)',
              }}
            >
              <div
                style={{
                  color: areAllCategoriesSelected() ? neutralWhite : neutralBlack,
                  opacity: areAllCategoriesSelected() ? 1 : isAllCategoriesHovered ? 0.85 : 0.7,
                  transition: 'all 0.25s ease',
                  fontSize: '20px',
                }}
              >
                ⋯
              </div>
            </div>
            <p
              style={{
                fontSize: '10px',
                fontWeight: areAllCategoriesSelected() ? 600 : isAllCategoriesHovered ? 550 : 500,
                color: areAllCategoriesSelected()
                  ? primaryPrimary
                  : isAllCategoriesHovered
                  ? primaryPrimary
                  : 'rgba(50, 50, 77, 0.85)',
                marginTop: 1,
                height: areAllCategoriesSelected() ? 'auto' : '22px',
                display: '-webkit-box',
                WebkitLineClamp: areAllCategoriesSelected() ? 'unset' : 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textAlign: 'center',
                lineHeight: '11px',
                transition: 'all 0.2s ease',
                letterSpacing: '0.1px',
                maxWidth: '100%',
                backgroundColor: 'transparent',
              }}
            >
              Todas las categorías
            </p>
          </div>

          {ALLMAINTAGSV3.map((category: MAINTAGV3Enum) => {
            const isSelected =
              filtersSelected[FilterOptionsEnum.MAINTAGV3].includes(category) &&
              filtersSelected[FilterOptionsEnum.MAINTAGV3].length === 1
            const isHovered = hoveredCategory === category

            return (
              <div
                key={category}
                ref={(el) => (categoryRefs.current[category] = el)}
                onClick={() => handleCategoryClick(category)}
                onMouseEnter={() => setHoveredCategory(category)}
                onMouseLeave={() => setHoveredCategory(null)}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  cursor: 'pointer',
                  minWidth: 56,
                  maxWidth: 56,
                  textAlign: 'center',
                  transition: 'transform 0.2s ease-out',
                  transform: isSelected
                    ? 'scale(1.03) translateY(-2px)'
                    : isHovered
                    ? 'scale(1.02) translateY(-1px)'
                    : 'scale(1) translateY(0)',
                  flexShrink: 0,
                  backgroundColor: 'transparent',
                }}
              >
                <div
                  style={{
                    width: 48,
                    height: 48,
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: isSelected
                      ? primaryPrimary
                      : isHovered
                      ? 'rgba(255, 255, 255, 1)'
                      : 'rgba(255, 255, 255, 0.9)',
                    boxShadow: isSelected
                      ? '0px 4px 8px rgba(66, 99, 235, 0.18)'
                      : isHovered
                      ? '0px 4px 6px rgba(0, 0, 0, 0.08)'
                      : '0px 2px 4px rgba(0, 0, 0, 0.05)',
                    transition: 'all 0.25s cubic-bezier(0.4, 0, 0.2, 1)',
                    marginBottom: 3,
                    border: isSelected
                      ? '1px solid transparent'
                      : isHovered
                      ? '1px solid rgba(66, 99, 235, 0.15)'
                      : '1px solid rgba(230, 232, 240, 0.6)',
                  }}
                >
                  <div
                    style={{
                      color: isSelected ? neutralWhite : neutralBlack,
                      opacity: isSelected ? 1 : isHovered ? 0.85 : 0.7,
                      transform: isSelected
                        ? 'scale(1.05) rotate(0deg)'
                        : isHovered
                        ? 'scale(1.03) rotate(0deg)'
                        : 'scale(1) rotate(0deg)',
                      transition: 'all 0.25s ease',
                    }}
                  >
                    {MAINTAGV3Icons({ width: 20 })[category]}
                  </div>
                </div>
                <p
                  style={{
                    fontSize: '10px',
                    fontWeight: isSelected ? 600 : isHovered ? 550 : 500,
                    color: isSelected
                      ? primaryPrimary
                      : isHovered
                      ? primaryPrimary
                      : 'rgba(50, 50, 77, 0.85)',
                    marginTop: 1,
                    height: isSelected ? 'auto' : '22px',
                    display: '-webkit-box',
                    WebkitLineClamp: isSelected ? 'unset' : 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textAlign: 'center',
                    lineHeight: '11px',
                    wordBreak: 'normal',
                    whiteSpace: 'pre-line',
                    transition: 'all 0.2s ease',
                    letterSpacing: '0.1px',
                    maxWidth: '100%',
                    backgroundColor: 'transparent',
                  }}
                >
                  {MAINTAGV3Label[category]}
                </p>
              </div>
            )
          })}
        </div>
      </div>

      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          height: '100%',
          width: '30px',
          background: 'linear-gradient(to right, transparent, rgba(255, 255, 255, 0.4) 70%)',
          pointerEvents: 'none',
          zIndex: 1,
          opacity: contentFits ? 0 : 1,
          transition: 'opacity 0.2s ease',
        }}
      />
    </div>
  )
}

export default MainTagV3Selector
