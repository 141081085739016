import React from 'react'
import { Drawer, Box, IconButton, Divider, Avatar } from '@mui/material'

import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'
import DiscountRoundedIcon from '@mui/icons-material/DiscountRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import ShareRoundedIcon from '@mui/icons-material/ShareRounded'
import { SMH2, SMP } from '../../../SMComponents/SMText'
import { neutral50White, primaryPrimary } from '../../../style'
import { DiscountsViewsEnum } from '../../../enums/DiscountsViewsEnum'
import { logSMEvent } from '../../../events/logEvent'
import LogoTransparenteAzul from '../../../assets/logo/LogoTransparenteAzul.png'
import Whatsapp from '../../../assets/icons/whatsappGrey.svg'
import { appVersion } from '../../../consts/version'

export const MENU_DRAWER_BODY_WIDTH = 'min(75vw, 300px)'

const DrawerOption = ({
  onClick,
  value,
  icon,
  label,
}: {
  onClick: (view: DiscountsViewsEnum) => void
  value: DiscountsViewsEnum
  icon: React.ReactNode
  label: string
}) => {
  return (
    <Box
      onClick={() => onClick(value)}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        color: neutral50White,
        py: 1.8,
        px: 2,
        borderRadius: 2,
        transition: 'all 0.2s ease',
        '&:hover': {
          backgroundColor: 'rgba(65, 105, 225, 0.08)',
          color: primaryPrimary,
          transform: 'translateX(4px)',
        },
        cursor: 'pointer',
      }}
    >
      <Avatar
        sx={{
          bgcolor: 'rgba(65, 105, 225, 0.08)',
          width: 32,
          height: 32,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: primaryPrimary,
        }}
      >
        {icon}
      </Avatar>
      <SMP sx={{ fontWeight: 500 }}>{label}</SMP>
    </Box>
  )
}

export const MenuDrawer = ({
  setView,
  openMenuDrawer,
  setOpenMenuDrawer,
}: {
  setView: (view: DiscountsViewsEnum) => void
  openMenuDrawer: boolean
  setOpenMenuDrawer: (newState: boolean) => void
}) => {
  const onClick = (newValue: DiscountsViewsEnum) => {
    logSMEvent('CHANGE_DISCOUNT_PAGE', { page: newValue })
    setView(newValue)
    setOpenMenuDrawer(false)
  }

  return (
    <Drawer
      anchor="right"
      open={openMenuDrawer}
      onClose={() => setOpenMenuDrawer(false)}
      sx={{
        zIndex: 200,
        '& .MuiDrawer-paper': {
          boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
          background: 'linear-gradient(180deg, #FFFFFF 0%, #F8FAFF 100%)',
        },
      }}
    >
      <Box
        sx={{
          width: MENU_DRAWER_BODY_WIDTH,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {/* Header */}
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 3,
              pb: 2,
              background:
                'linear-gradient(90deg, rgba(65, 105, 225, 0.08) 0%, rgba(255, 255, 255, 0) 100%)',
              borderBottom: '1px solid rgba(65, 105, 225, 0.1)',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2.5,
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  width: 40,
                  height: 40,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  overflow: 'hidden',
                  boxShadow: '0 4px 12px rgba(65, 105, 225, 0.2)',
                }}
              >
                <img
                  src={LogoTransparenteAzul}
                  style={{
                    width: '90%',
                    height: '90%',
                    objectFit: 'cover',
                    transition: 'transform 0.3s ease',
                  }}
                  alt="Logo"
                />
              </Box>
              <SMH2
                sx={{
                  color: primaryPrimary,
                  fontWeight: 700,
                  background: 'linear-gradient(90deg, #4169E1 0%, #5C7CFA 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  letterSpacing: '0.5px',
                }}
              >
                SaveMoney
              </SMH2>
            </Box>
            <IconButton
              onClick={() => setOpenMenuDrawer(false)}
              sx={{
                color: neutral50White,
                '&:hover': {
                  backgroundColor: 'rgba(65, 105, 225, 0.08)',
                  color: primaryPrimary,
                },
              }}
            >
              <CloseRoundedIcon />
            </IconButton>
          </Box>

          {/* Menu Options */}
          <Box sx={{ px: 1, pt: 3 }}>
            <DrawerOption
              onClick={onClick}
              value={DiscountsViewsEnum.DISCOUNT_DISPLAY}
              icon={<DiscountRoundedIcon sx={{ fontSize: 18 }} />}
              label="Descuentos"
            />

            <DrawerOption
              onClick={onClick}
              value={DiscountsViewsEnum.SAVED_LIST}
              icon={<BookmarkRoundedIcon sx={{ fontSize: 18 }} />}
              label="Guardados"
            />

            <DrawerOption
              onClick={onClick}
              value={DiscountsViewsEnum.ACCOUNT}
              icon={<PersonRoundedIcon sx={{ fontSize: 18 }} />}
              label="Tu perfil"
            />
          </Box>
        </Box>

        {/* Footer with contact */}
        <Box sx={{ p: 3, mt: 2 }}>
          <Divider
            sx={{
              mb: 2,
              borderColor: 'rgba(65, 105, 225, 0.1)',
              '&::before, &::after': {
                borderColor: 'rgba(65, 105, 225, 0.1)',
              },
            }}
          />
          <Box
            component="a"
            href="https://wa.me/56974769848"
            target="_blank"
            rel="noopener"
            sx={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              color: '#128C7E', // WhatsApp green
              p: 1.5,
              borderRadius: 2,
              transition: 'all 0.2s ease',
              border: '1px solid rgba(18, 140, 126, 0.15)',
              '&:hover': {
                backgroundColor: 'rgba(18, 140, 126, 0.05)',
                boxShadow: '0px 4px 12px rgba(18, 140, 126, 0.1)',
              },
            }}
          >
            <Avatar
              sx={{
                bgcolor: 'rgba(18, 140, 126, 0.1)',
                width: 32,
                height: 32,
                mr: 1.5,
              }}
            >
              <img src={Whatsapp} alt="Whatsapp" width={'18px'} />
            </Avatar>
            <SMP sx={{ fontWeight: 600, color: '#128C7E' }}>Contáctanos</SMP>
            <OpenInNewRoundedIcon sx={{ width: 16, ml: 'auto', color: 'inherit' }} />
          </Box>
          {/* Add spacing between buttons */}
          <Box sx={{ height: 16 }} /> {/* This adds a 16px gap */}
          {/* Referral Button */}
          <Box>
            <Box
              component="a"
              href={(() => {
                const shareText = `
¡Hola! Te invito a que descargues la app de SaveMoney para que te enteres de todos los descuentos que tienes con tus tarjetas.

👉 AppStore: https://apps.apple.com/us/app/savemoneycl/id6465695551

👉 Google Play: https://play.google.com/store/apps/details?id=cl.savemoney.www.twa
`
                return `https://api.whatsapp.com/send?text=${encodeURIComponent(shareText)}`
              })()}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.stopPropagation()
                logSMEvent('MENU_DRAWER_SHARE_BUTTON_CLICKED', {
                  source: 'menu_drawer',
                })
              }}
              sx={{
                width: '100%',
                py: 1.5,
                backgroundColor: primaryPrimary,
                color: '#FFFFFF',
                borderRadius: 3,
                boxShadow: '0 2px 8px rgba(65, 105, 225, 0.2)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                transition: 'background-color 0.3s',
                textDecoration: 'none',
                '&:hover': {
                  backgroundColor: '#3A5FD9',
                  textDecoration: 'none',
                },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                  sx={{
                    bgcolor: 'rgba(255, 255, 255, 0.2)',
                    width: 34,
                    height: 34,
                    mr: 2,
                  }}
                >
                  <ShareRoundedIcon sx={{ fontSize: 18, color: '#FFFFFF' }} />
                </Avatar>
                <Box>
                  <SMP sx={{ fontWeight: 600, color: '#FFFFFF', lineHeight: 1.2 }}>
                    Invita a tus amigos
                  </SMP>
                  <SMP sx={{ fontSize: '0.75rem', color: 'rgba(255, 255, 255, 0.85)' }}>
                    Comparte la app
                  </SMP>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              mt: 4,
              textAlign: 'center',
              fontSize: '12px',
              color: 'rgba(0, 0, 0, 0.4)',
            }}
          >
            © SaveMoney 2023
            <Box sx={{ fontSize: '10px', mt: 0.5 }}>v{appVersion}</Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
}
