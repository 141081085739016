import React, { useEffect, useState } from 'react'
import { Container, Button, Box, Link, Stack } from '@mui/material'
import { neutralWhite, primaryPrimary } from '../../../style'
import SaveMoneyLogo from '../../../assets/logo/logoTransparentBackground.png'
import Landing1 from '../../../assets/frames/landing1.png'
import { logSMEvent } from '../../../events/logEvent'
import MacLogo from '../../../assets/icons/macLogo.svg'
import Facebook from '../../../assets/icons/facebook.svg'
import Instagram from '../../../assets/icons/instagram.svg'
import Tiktok from '../../../assets/icons/tiktok.svg'
import GoogleLogo from '../../../assets/icons/googleLogo.svg'
import Whatsapp from '../../../assets/icons/whatsapp.svg'
import Email from '../../../assets/icons/email.svg'
import { appVersion } from '../../../consts/version'
import { useNavigate } from 'react-router-dom'
import './landing.css'
import { motion, useAnimation } from 'framer-motion'
// xs is in pixels = to 600px

// Counter component for animated statistics
const CounterItem = ({
  endValue,
  label,
  delay = 0,
}: {
  endValue: number
  label: string
  delay: number
}) => {
  const [count, setCount] = useState(0)
  const controls = useAnimation()

  useEffect(() => {
    const handleInView = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          controls.start({ opacity: 1, y: 0 })

          // Start counter animation
          let startTime: number | undefined
          const duration = 2000 // 2 seconds

          const animateCount = (timestamp: number) => {
            if (!startTime) startTime = timestamp
            const progress = Math.min((timestamp - startTime) / duration, 1)
            const currentCount = Math.floor(progress * endValue)

            setCount(currentCount)

            if (progress < 1) {
              requestAnimationFrame(animateCount)
            } else {
              setCount(endValue)
            }
          }

          setTimeout(() => {
            requestAnimationFrame(animateCount)
          }, delay * 1000)
        }
      })
    }

    const observer = new IntersectionObserver(handleInView, { threshold: 0.1 })
    const elements = document.querySelectorAll('.counter-item')
    elements.forEach((el) => observer.observe(el))

    return () => {
      elements.forEach((el) => observer.unobserve(el))
    }
  }, [endValue, controls, delay])

  return (
    <motion.div
      className="counter-item"
      initial={{ opacity: 0, y: 20 }}
      animate={controls}
      transition={{ duration: 0.6, delay }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: { xs: '0.5rem', md: '1rem' },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
          <Box
            component="span"
            sx={{
              fontSize: { xs: '2.2rem', md: '3.5rem' },
              fontWeight: 700,
              color: neutralWhite,
              lineHeight: 1.2,
            }}
          >
            +{count}K
          </Box>
        </Box>
        <Box
          component="p"
          sx={{
            fontSize: { xs: '0.9rem', md: '1.2rem' },
            fontWeight: 500,
            color: 'rgba(255,255,255,0.8)',
            mt: { xs: 0.5, md: 1 },
            maxWidth: '200px',
          }}
        >
          {label}
        </Box>
      </Box>
    </motion.div>
  )
}

export const WebLanding = () => {
  const navigate = useNavigate()

  // Scroll animation effect
  useEffect(() => {
    const handleScroll = () => {
      const scrollElements = document.querySelectorAll('.scroll-animation')
      scrollElements.forEach((element) => {
        const elementTop = element.getBoundingClientRect().top
        const elementVisible = 150
        if (elementTop < window.innerHeight - elementVisible) {
          element.classList.add('active')
        }
      })
    }

    window.addEventListener('scroll', handleScroll)
    // Trigger once on load
    handleScroll()

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <Box
      sx={{
        backgroundColor: primaryPrimary,
        minHeight: '100vh',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      {/* Background gradient effect */}
      <Box
        sx={{
          position: 'absolute',
          top: '-10%',
          right: '-10%',
          width: '500px',
          height: '500px',
          borderRadius: '50%',
          background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)',
          filter: 'blur(40px)',
          zIndex: 0,
        }}
      />

      <Container
        maxWidth="xl"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 12,
          paddingBottom: 4,
          position: 'relative',
          zIndex: 1,
        }}
      >
        {/* Header */}
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.6 }}
          style={{ width: '100%' }}
        >
          <Stack
            direction="row"
            sx={{
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              pt: 2,
              pb: 1,
              backdropFilter: 'blur(8px)',
              position: 'sticky',
              top: 0,
              zIndex: 10,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <motion.img
                src={SaveMoneyLogo}
                alt="App Logo"
                style={{ width: 40, height: 40 }}
                whileHover={{ rotate: 10, scale: 1.1 }}
                transition={{ type: 'spring', stiffness: 300 }}
              />
              <h1 className="landing-header-font">SaveMoney</h1>
            </Box>
          </Stack>
        </motion.div>

        {/* Main Section */}
        <Container
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            gap: { xs: 0, md: 10 },
            margin: '-70px 0px',
            minWidth: '100%',
          }}
        >
          {/* Title and description */}
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            style={{ flex: 1 }}
          >
            <Box
              sx={{
                flex: 1,
                textAlign: { xs: 'center', md: 'left' },
                mb: { xs: 4, md: 0 },
              }}
            >
              <h1 className="landing-title">Ahorra dinero con SaveMoney</h1>

              {/* Versión para móviles */}
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <h2 className="landing-description" style={{ fontSize: '1.2rem', fontWeight: 600 }}>
                  IA gratuita que encuentra descuentos en segundos
                </h2>
              </Box>

              {/* Versión para desktop */}
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <h2 className="landing-description">
                  SaveMoney es una IA 100% gratuita que encuentra los mejores descuentos para ti en
                  segundos. Reunimos ofertas de tus tarjetas bancarias, tarjetas de fidelidad,
                  compañías de celular, billeteras digitales y más en un solo lugar.
                </h2>
                <p
                  className="landing-description"
                  style={{ marginTop: '1rem', fontSize: '1.1rem' }}
                >
                  <strong>¡Inicia sesión en menos de un minuto y comienza a ahorrar hoy!</strong>
                </p>
              </Box>

              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <Button
                  sx={{
                    borderRadius: 40,
                    backgroundColor: neutralWhite,
                    textTransform: 'none',
                    marginTop: 4,
                    marginBottom: 4,
                    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.15)',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      backgroundColor: neutralWhite,
                      boxShadow: '0px 12px 25px rgba(0, 0, 0, 0.25)',
                    },
                  }}
                  onClick={(e: any) => {
                    logSMEvent('LANDING_PRUEBA')
                    e.stopPropagation()
                    return navigate('/clubs')
                  }}
                  variant="contained"
                >
                  <p
                    className="landing-button-text"
                    style={{
                      color: primaryPrimary,
                      margin: '4px 34px',
                      textWrap: 'nowrap',
                    }}
                  >
                    Comenzar ahora - Es gratis
                  </p>
                </Button>
              </motion.div>
              <p className="conditions-font">
                Al continuar aceptas nuestros{' '}
                <Link
                  href="https://www.savemoney.cl/terminos.html"
                  target="_blank"
                  rel="noopener"
                  style={{
                    color: neutralWhite,
                    textDecoration: 'underline',
                    transition: 'all 0.2s ease',
                  }}
                  className="terms-link"
                >
                  términos y condiciones
                </Link>
              </p>
            </Box>
          </motion.div>

          {/* Phone frames + download */}
          <motion.div
            initial={{ x: 50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <motion.img
                src={Landing1}
                alt="App Screenshot"
                style={{
                  maxWidth: '90%',
                  maxHeight: '80vh',
                  filter: 'drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.25))',
                }}
                initial={{ y: 20 }}
                animate={{ y: [0, -10, 0] }}
                transition={{
                  repeat: Infinity,
                  duration: 4,
                  ease: 'easeInOut',
                }}
              />
              <Container
                sx={{
                  display: 'flex',
                  gap: 2,
                  flexDirection: { xs: 'column', md: 'row' },
                  justifyContent: 'center',
                  alignItems: 'center',
                  mt: 4,
                  width: { xs: '100%', md: 'auto' },
                }}
              >
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  style={{ width: '100%', maxWidth: '240px' }}
                >
                  <Button
                    onClick={() => {
                      window.open(
                        'https://apps.apple.com/us/app/savemoneycl/id6465695551',
                        '_blank'
                      )
                      logSMEvent('CLICK_APP_STORE_CONTACT')
                    }}
                    sx={{
                      borderRadius: 40,
                      padding: '8px 28px',
                      backgroundColor: neutralWhite,
                      textTransform: 'none',
                      gap: 2,
                      boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
                      transition: 'all 0.3s ease',
                      width: '100%',
                      justifyContent: 'center',
                      '&:hover': {
                        backgroundColor: neutralWhite,
                        boxShadow: '0px 12px 20px rgba(0, 0, 0, 0.2)',
                      },
                    }}
                  >
                    <img src={MacLogo} alt="Mac" width={'28px'} />
                    <p className="landing-button-text" style={{ color: primaryPrimary }}>
                      App Store
                    </p>
                  </Button>
                </motion.div>
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  style={{ width: '100%', maxWidth: '240px' }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      window.open(
                        'https://play.google.com/store/apps/details?id=cl.savemoney.www.twa',
                        '_blank'
                      )
                      logSMEvent('CLICK_GOOGLE_STORE_CONTACT')
                    }}
                    sx={{
                      borderRadius: 40,
                      padding: '8px 28px',
                      borderColor: neutralWhite,
                      borderWidth: '2px',
                      textTransform: 'none',
                      color: neutralWhite,
                      gap: 2,
                      width: '100%',
                      justifyContent: 'center',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        borderColor: neutralWhite,
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      },
                    }}
                  >
                    <img src={GoogleLogo} alt="Goo" width={'28px'} />
                    <p className="landing-button-text">Google Play</p>
                  </Button>
                </motion.div>
              </Container>
            </Box>
          </motion.div>
        </Container>

        {/* Placeholder for other sections */}
        <Container className="scroll-animation">
          {/* Add your app explanation sections here */}
        </Container>

        {/* Follow Us Section */}
        <motion.div
          className="scroll-animation"
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          style={{ width: '100%' }}
        >
          <Box sx={{ textAlign: 'center', gap: 10, width: '100%' }}>
            <p className="title-2">Síguenos</p>
            <Stack
              direction="row"
              justifyContent="center"
              spacing={6}
              sx={{ width: '100%', mt: 4 }}
            >
              <motion.div whileHover={{ y: -5, scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                <Link
                  href="https://www.tiktok.com/@savemoney.cl"
                  target="_blank"
                  rel="noopener"
                  style={{ color: neutralWhite }}
                >
                  <Box
                    sx={{
                      background: 'rgba(0,0,0,0.9)',
                      borderRadius: '50%',
                      p: 2,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      transition: 'all 0.3s ease',
                      '&:hover': { background: 'rgba(0,0,0,0.7)' },
                    }}
                  >
                    <img src={Tiktok} alt="Tiktok" width={40} />
                  </Box>
                </Link>
              </motion.div>
              <motion.div whileHover={{ y: -5, scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                <Link
                  href="https://www.instagram.com/savemoney.cl/"
                  target="_blank"
                  rel="noopener"
                  style={{ color: neutralWhite }}
                >
                  <Box
                    sx={{
                      background:
                        'linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%)',
                      borderRadius: '50%',
                      p: 2,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      transition: 'all 0.3s ease',
                      '&:hover': { opacity: 0.9 },
                    }}
                  >
                    <img src={Instagram} alt="Instagram" width={40} />
                  </Box>
                </Link>
              </motion.div>
              <motion.div whileHover={{ y: -5, scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                <Link
                  href="https://www.facebook.com/savemoneycl"
                  target="_blank"
                  rel="noopener"
                  style={{ color: neutralWhite }}
                >
                  <Box
                    sx={{
                      background: '#1877F2',
                      borderRadius: '50%',
                      p: 2,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      transition: 'all 0.3s ease',
                      '&:hover': { background: '#0e6edf' },
                    }}
                  >
                    <img src={Facebook} alt="Facebook" width={40} />
                  </Box>
                </Link>
              </motion.div>
            </Stack>
          </Box>
        </motion.div>

        {/* Contact Us Section */}
        <motion.div
          className="scroll-animation"
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.2 }}
          style={{ width: '100%' }}
        >
          <Box
            sx={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              width: '100%',
            }}
          >
            <p className="title-2">Contáctanos</p>
            <Container
              sx={{
                display: 'flex',
                gap: 2,
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: 'center',
              }}
            >
              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <Button
                  onClick={() => {
                    window.open('https://wa.me/56974769848', '_blank')
                    logSMEvent('CLICK_WHATSAPP_CONTACT')
                  }}
                  sx={{
                    borderRadius: 40,
                    backgroundColor: neutralWhite,
                    textTransform: 'none',
                    minWidth: { xs: '100%', sm: 200 },
                    width: { xs: '100%', sm: 'auto' },
                    gap: 2,
                    padding: '12px 32px',
                    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      backgroundColor: neutralWhite,
                      boxShadow: '0px 12px 20px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                >
                  <img src={Whatsapp} alt="Whatsapp" width={'24px'} />
                  <p className="landing-button-text" style={{ color: primaryPrimary, margin: 0 }}>
                    Whatsapp
                  </p>
                </Button>
              </motion.div>
              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    window.location.href = 'mailto:wayoalamos@savemoney.cl'
                    logSMEvent('CLICK_EMAIL_CONTACT')
                  }}
                  sx={{
                    borderRadius: 40,
                    borderColor: neutralWhite,
                    borderWidth: '2px',
                    textTransform: 'none',
                    color: neutralWhite,
                    minWidth: { xs: '100%', sm: 200 },
                    width: { xs: '100%', sm: 'auto' },
                    gap: 2,
                    padding: '12px 32px',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      borderColor: neutralWhite,
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    },
                  }}
                >
                  <img src={Email} alt="Email" width={'24px'} />
                  <p className="landing-button-text" style={{ margin: 0 }}>
                    Mail
                  </p>
                </Button>
              </motion.div>
            </Container>
          </Box>
        </motion.div>

        {/* Stats Counters Section */}
        <Box sx={{ width: '100%', marginTop: '2rem', px: { xs: 1, sm: 2, md: 3 } }}>
          <motion.div
            className="scroll-animation"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.3 }}
          >
            <Box
              sx={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                background: 'rgba(255,255,255,0.05)',
                borderRadius: '16px',
                padding: { xs: '1.5rem', md: '2rem' },
                backdropFilter: 'blur(10px)',
                width: '90%',
                maxWidth: '1200px',
                mx: 'auto',
              }}
            >
              <p className="title-2">SaveMoney en números</p>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  gap: { xs: 3, md: 4 },
                  width: '100%',
                  mt: { xs: 1, md: 2 },
                }}
              >
                <Box
                  sx={{
                    width: { xs: '100%', sm: '45%', md: '30%' },
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <CounterItem endValue={50} label="Usuarios activos mensuales" delay={0.1} />
                </Box>
                <Box
                  sx={{
                    width: { xs: '100%', sm: '45%', md: '30%' },
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <CounterItem endValue={170} label="Descargas en las stores" delay={0.3} />
                </Box>
                <Box
                  sx={{
                    width: { xs: '100%', sm: '100%', md: '30%' },
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <CounterItem endValue={200} label="Visitas al mes" delay={0.5} />
                </Box>
              </Box>
            </Box>
          </motion.div>
        </Box>

        {/* Version */}
        <Box
          sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            opacity: 0.7,
            mt: 4,
          }}
        >
          <p className="conditions-font">v {appVersion}</p>
        </Box>
      </Container>
    </Box>
  )
}
