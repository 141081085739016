import DiscountsHolder from './DiscountsHolder'
import { SuggestedBottoms } from './SuggestedBottoms'
import React from 'react'
import NoDiscountsFound from './NoDiscountsFound'
import { Discount } from '../../../types/discount'
import { HomeBannerFrameInDiscountList } from './Banner'

export const DiscountScreen = function ({
  discountsToShow,
  search,
  handleChangeSearch,
  isStar,
}: {
  discountsToShow: Discount[]
  search: string
  handleChangeSearch: (search: string) => void
  isStar: boolean
}) {
  if (discountsToShow.length === 0) {
    return <NoDiscountsFound search={search} handleChangeSearch={handleChangeSearch} />
  }
  return (
    <>
      {isStar && <SuggestedBottoms />}
      <div style={{ display: 'flex', flexDirection: 'column', margin: '0px 10px', gap: 20 }}>
        <HomeBannerFrameInDiscountList />
        <div style={{ display: 'flex', flexDirection: 'column', gap: 40 }}>
          <DiscountsHolder discountsToShow={discountsToShow} isStar={isStar} />
        </div>
      </div>
    </>
  )
}
