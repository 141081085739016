import React, { useContext, useState } from 'react'
import { SMContent, SMFooter } from '../../../SMComponents/SMView'
import { SMH2, SMH3, SMP } from '../../../SMComponents/SMText'
import { OpenBookTableDrawerProps } from '../../../contexts/DiscountContext'
import { Box, List, ListItem, ListItemText, Divider, SwipeableDrawer, Modal } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { RestaurantReservationInfo } from '../../../types/reservationInfo'
import { logSMEvent } from '../../../events/logEvent'
import { AppContext } from '../../../contexts/AppContext'
import { addUtmParamsToUrl } from '../../../helpers/others'

export const BookTableDrawer = ({
  open,
  setOpen,
  bookTableDrawerProps,
}: {
  open: boolean
  setOpen: (newState: any) => void
  bookTableDrawerProps: OpenBookTableDrawerProps
}) => {
  const { auth } = useContext(AppContext)
  const { reservationInfo } = bookTableDrawerProps

  const logInfo = {
    userId: auth ? auth.id : null,
    isLoggedIn: auth ? true : false,
    authPhoneNumber: auth ? auth.phoneNumber : null,
    authName: auth ? auth.name : null,
    authEmail: auth ? auth.email : null,
  }

  const [showIframe, setShowIframe] = useState(false)
  const [selectedRestaurant, setSelectedRestaurant] = useState<RestaurantReservationInfo | null>(
    null
  )

  const IframeModal = () => (
    <Modal
      open={showIframe}
      onClose={() => setShowIframe(false)}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&::before': {
          content: '""',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'rgba(255, 255, 255, 0.3)',
          backdropFilter: 'blur(8px)',
          zIndex: -1,
        },
      }}
    >
      <Box
        sx={{
          width: '90%',
          maxWidth: '600px',
          maxHeight: '90vh',
          backgroundColor: 'white',
          borderRadius: '16px',
          boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px 24px',
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
          }}
        >
          <SMH3>{selectedRestaurant?.name}</SMH3>
          <Box
            onClick={() => setShowIframe(false)}
            sx={{
              cursor: 'pointer',
              padding: '8px',
              borderRadius: '50%',
              '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
            }}
          >
            ✕
          </Box>
        </Box>
        {selectedRestaurant && (
          <iframe
            src={addUtmParamsToUrl({ url: selectedRestaurant.reservationUrl })}
            style={{
              width: '100%',
              height: 'calc(90vh - 70px)',
              border: 'none',
            }}
            title={`Reserva en ${selectedRestaurant.name}`}
          />
        )}
      </Box>
    </Modal>
  )

  return (
    <>
      <SwipeableDrawer
        open={open}
        swipeAreaWidth={0}
        disableSwipeToOpen={false}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        anchor="bottom"
        sx={{
          zIndex: 300,
          '& .MuiDrawer-paper': {
            borderTopLeftRadius: 28,
            borderTopRightRadius: 28,
            maxHeight: '85vh',
            boxShadow: '0px -8px 30px rgba(0, 0, 0, 0.15)',
            background: 'rgba(255, 255, 252, 0.9)',
            backdropFilter: 'blur(5px)',
          },
        }}
      >
        <Box
          sx={{
            width: '60px',
            height: '5px',
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
            borderRadius: '10px',
            margin: '16px auto',
          }}
        />
        <SMContent
          sx={{
            overflowY: 'auto',
            padding: '12px 24px 28px',
          }}
        >
          <SMH2
            sx={{
              mb: 4,
              textAlign: 'center',
              color: 'text.primary',
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: '-12px',
                left: '50%',
                transform: 'translateX(-50%)',
                height: '3px',
                background: 'linear-gradient(90deg, #4776E6 0%, #8E54E9 100%)',
                borderRadius: '10px',
              },
            }}
          >
            Locales Disponibles
          </SMH2>
          <List sx={{ width: '100%', mb: 2 }}>
            {reservationInfo.map((restaurant: RestaurantReservationInfo, index: number) => (
              <React.Fragment key={restaurant.slug || index}>
                <ListItem
                  onClick={(e) => {
                    e.preventDefault()
                    logSMEvent(`BOOK_TABLE_CLICKED_2`, { ...logInfo, ...restaurant })
                    setSelectedRestaurant(restaurant)
                    setShowIframe(true)
                  }}
                  sx={{
                    borderRadius: 3,
                    transition: 'all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      cursor: 'pointer',
                      boxShadow: '0 15px 25px -12px rgba(0, 0, 0, 0.15)',
                    },
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '18px 20px',
                    my: 2,
                    background: 'rgba(255, 255, 255, 0.8)',
                    backdropFilter: 'blur(4px)',
                    boxShadow:
                      '0 8px 20px -8px rgba(0, 0, 0, 0.12), 8px 0 20px -8px rgba(0, 0, 0, 0.06), -8px 0 20px -8px rgba(0, 0, 0, 0.06)',
                    border: 'none',
                  }}
                >
                  <ListItemText
                    primary={<SMH3>{restaurant.name}</SMH3>}
                    secondary={
                      <SMP
                        sx={{
                          color: 'rgba(0, 0, 0, 0.6)',
                          mt: 1,
                          display: 'flex',
                          alignItems: 'center',
                          gap: '4px',
                          fontSize: '0.875rem',
                        }}
                      >
                        <LocationOnIcon
                          sx={{
                            fontSize: '16px',
                            mr: 0.5,
                            color: restaurant.foundInfoInApi ? 'rgba(0, 0, 0, 0.6)' : 'black',
                          }}
                        />
                        {restaurant.address}
                      </SMP>
                    }
                  />
                </ListItem>
                {index < reservationInfo.length - 1 && (
                  <Box sx={{ opacity: 0.3, mx: 'auto', width: '75%' }}>
                    <Divider variant="fullWidth" sx={{ borderStyle: 'dashed' }} />
                  </Box>
                )}
              </React.Fragment>
            ))}
          </List>
        </SMContent>
        <SMFooter
          sx={{
            padding: '20px 24px 32px',
            textAlign: 'center',
            background: 'rgba(250, 250, 252, 0.8)',
            backdropFilter: 'blur(4px)',
            borderTop: '1px solid rgba(0, 0, 0, 0.05)',
          }}
        >
          <SMP
            sx={{
              fontWeight: 500,
              color: 'rgba(0, 0, 0, 0.7)',
              fontSize: '1rem',
              letterSpacing: '0.01em',
            }}
          >
            Selecciona un local para hacer una reserva
          </SMP>
        </SMFooter>
      </SwipeableDrawer>
      <IframeModal />
    </>
  )
}
