import { TextField } from '@mui/material'
import React, { useState } from 'react'

import { styled } from '@mui/material/styles'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Button } from '@mui/material'
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded'
import { useNavigate } from 'react-router-dom'

import { neutral90White, neutralWhite, primary80White, primaryPrimary } from '../../../style'
import { HeaderFrame } from './HeaderFrame'
import { SHOW_SEE_DISCOUNTS_BUTTON } from '../../intro/screens/IIntro'

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: primaryPrimary,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'black',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: neutral90White,
    },
    '&:hover fieldset': {
      borderColor: primary80White,
    },
    '&.Mui-focused fieldset': {
      borderColor: primaryPrimary,
    },
  },
})

const TextFieldWrapper = styled('div')({
  position: 'relative',
  display: 'inline-flex',
  width: '100%',
  maxWidth: '700px',
})

export function TopDiscountDisplayFrame({
  search,
  handleChangeSearch,
}: {
  search: string
  handleChangeSearch: (newSearch: string) => void
}) {
  const [isFocused, setFocused] = useState(false)
  const navigate = useNavigate()

  const TextFieldIconContainer = styled('div')`
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 50%;
    border-radius: 50%;
    padding: 6px;
    right: 10px;
    transform: translateY(-50%);
    background-color: ${isFocused ? neutralWhite : primaryPrimary};
    color: ${isFocused ? primaryPrimary : neutralWhite};
    box-shadow: ${!isFocused && search ? '0px 2px 4px rgba(180, 181, 186)' : 'none'};
  `
  return (
    <HeaderFrame removeLeftDiv>
      <Button
        onClick={() => {
          return navigate('/clubs', SHOW_SEE_DISCOUNTS_BUTTON(true))
        }}
        style={{
          textTransform: 'none',
          gap: 4,
        }}
      >
        <ReplayRoundedIcon />
      </Button>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <TextFieldWrapper>
          <CssTextField
            variant="outlined"
            fullWidth
            placeholder="Buscar descuento"
            // label={<p style={{ margin: '0px 0px 0px 14px' }}>Buscar descuento</p>}
            InputProps={{
              style: {
                borderRadius: 40,
                backgroundColor: neutralWhite,
                height: 44,
              },
            }}
            value={search}
            onChange={(e) => {
              return handleChangeSearch(e.target.value)
            }}
            onFocus={() => {
              return setFocused(true)
            }}
            onBlur={() => {
              return setFocused(false)
            }}
          />
          <TextFieldIconContainer>
            {search && !isFocused ? (
              <CloseRoundedIcon
                onClick={() => {
                  return handleChangeSearch('')
                }}
                sx={{ maxHeight: '20px', maxWidth: '20px' }}
              />
            ) : (
              <SearchRoundedIcon sx={{ maxHeight: '20px', maxWidth: '20px' }} />
            )}
          </TextFieldIconContainer>
        </TextFieldWrapper>
      </div>
    </HeaderFrame>
  )
}
