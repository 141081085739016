import React, { useContext, useEffect, useState } from 'react'
import { Discount } from '../../../types/discount'
import { SMPrimaryButton } from '../../../SMComponents/SMButton'
import { SMP } from '../../../SMComponents/SMText'
import { logSMEvent } from '../../../events/logEvent'
import { AppContext } from '../../../contexts/AppContext'
import useObserver from '../../../hooks/useObserver'
import { todayChar } from '../../../helpers/getDaysFilters'
import { DiscountContext } from '../../../contexts/DiscountContext'
import { RestaurantReservationInfo } from '../../../types/reservationInfo'

export enum CTAButtonSource {
  CARD_FOOTER = 'card_footer',
  DISCOUNT_DRAWER = 'discount_drawer',
  MAP_CARD = 'map_card',
}

export const BookTableButton = ({
  discount,
  source,
}: {
  discount: Discount
  source: CTAButtonSource
}) => {
  const { auth } = useContext(AppContext)
  const { openBookTableDrawer } = useContext(DiscountContext)
  const [wasObserved, setWasObserved] = useState(false) // is or was observed

  const handleObservation = () => {
    setWasObserved(true)
  }

  const componentRef = useObserver(handleObservation)

  const CTAlogInfo = {
    discountId: discount.id,
    discountClub: discount.club,
    discountTitle: discount.titulo,
    isCoverManager: String(discount.isCoverManager),
    hasCoverManagerVariablePlan: String(discount.hasCoverManagerVariablePlan),
    discountDays: discount.diasNormalizados,
    dayOfTheWeek: todayChar,
    source,
    isPromotedDiscount: discount.promoted,
    userId: auth ? auth.id : null,
    ctaText: discount.CTA?.text,
    ctaUrl: discount.CTA?.url,
    isLoggedIn: auth ? true : false,
    authPhoneNumber: auth ? auth.phoneNumber : null,
    authName: auth ? auth.name : null,
    authEmail: auth ? auth.email : null,
  }

  useEffect(() => {
    if (wasObserved) {
      logSMEvent(`BOOK_TABLE_OBSERVED_${source}`, CTAlogInfo)
    }
  }, [wasObserved])

  const discountsReservationInfo = discount?.gMapsResults
    ?.filter((r) => r.reservationInfo)
    .map((r) => r.reservationInfo)
    .filter((r): r is RestaurantReservationInfo => r !== undefined && r !== null && r !== false)
  if (discountsReservationInfo && discountsReservationInfo.length > 0) {
    return (
      <SMPrimaryButton
        ref={componentRef}
        fullWidth={false}
        small={true}
        sx={{
          minWidth: 'fit-content',
          minHeight: 0,
          maxHeight: 32,
          height: 32,
          padding: 10,
          textAlign: 'start',
          color: '#4A75E8', // Blue text for good contrast
          backgroundColor: 'transparent', // Transparent background
          border: '1.5px solid #4A75E8', // Blue border
          borderRadius: '40px',
          gap: 6,
          whiteSpace: 'nowrap',
          maxWidth: 240,
          fontWeight: 600, // Slightly bolder for better visibility
          '&:hover': {
            backgroundColor: 'rgba(74, 117, 232, 0.08)', // Very light blue background on hover
            borderColor: '#395DC7', // Slightly darker border on hover
            color: '#395DC7', // Darker text on hover
          },
        }}
        onClick={(e: any) => {
          e.stopPropagation()
          logSMEvent(`BOOK_TABLE_CLICKED`, CTAlogInfo)
          openBookTableDrawer(discountsReservationInfo)
        }}
      >
        <SMP sx={{ color: 'inherit' }}>Reservar mesa</SMP>
      </SMPrimaryButton>
    )
  }

  return null
}
