import React, { useContext } from 'react'
import SwapVertRoundedIcon from '@mui/icons-material/SwapVertRounded'
import { AppContext } from '../../../contexts/AppContext'

export default function SortButton() {
  const { setNavButtonClicked } = useContext(AppContext)

  return (
    <div
      onClick={() => setNavButtonClicked('Sort')}
      style={{
        cursor: 'pointer',
        display: 'flex',
        gap: 4,
        width: 'fit-content',
        padding: '4px 8px',
        borderRadius: '16px',
        backgroundColor: 'transparent',
        color: '#5a6978',
        border: '1px solid',
        borderColor: 'rgba(0, 0, 0, 0.12)',
        boxShadow: 'none',
        flexWrap: 'nowrap',
        whiteSpace: 'nowrap',
        fontSize: '0.85rem',
        alignItems: 'center',
        transition: 'all 0.2s ease',
        fontWeight: 400,
      }}
    >
      <div style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
        <SwapVertRoundedIcon fontSize="small" />
      </div>
      <p style={{ margin: 0 }}>Ordenar</p>
    </div>
  )
}
