import React from 'react'
import { ToggleButtonGroup, ToggleButton, styled } from '@mui/material'
import { DiscountsDisplayEnum } from '../../../enums/DiscountsViewsEnum'
import { logSMEvent } from '../../../events/logEvent'
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded'
import TableChartRoundedIcon from '@mui/icons-material/TableChartRounded'
import MapRoundedIcon from '@mui/icons-material/MapRounded'

interface DisplayModeSelectorProps {
  displayMode: DiscountsDisplayEnum
  onChangeDisplayMode: (mode: DiscountsDisplayEnum) => void
}

// Styled ToggleButtonGroup for a more polished look
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  backgroundColor: '#EBF0FF',
  borderRadius: 16,
  padding: 3,
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)',
  width: 'auto',
  margin: '0 auto',
  '& .MuiToggleButtonGroup-grouped': {
    border: 0,
    '&.Mui-selected': {
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
    },
    '&:not(:first-of-type)': {
      borderRadius: 12,
      marginLeft: 4,
    },
    '&:first-of-type': {
      borderRadius: 12,
    },
  },
}))

// Styled ToggleButton for consistent sizing and better touch targets
const StyledToggleButton = styled(ToggleButton)(() => ({
  textTransform: 'none',
  minWidth: 90,
  height: 32,
  borderRadius: 12,
  color: 'rgba(0, 0, 0, 0.65)',
  '&.Mui-selected': {
    color: '#4361EE',
    backgroundColor: '#FFFFFF',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
  },
  display: 'flex',
  gap: 8,
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.2s ease-in-out',
  padding: '0 14px',
}))

export const DisplayModeSelector = ({
  displayMode,
  onChangeDisplayMode,
}: DisplayModeSelectorProps) => {
  const changeDisplayMode = (mode: DiscountsDisplayEnum) => {
    logSMEvent('CHANGE_DISPLAY_MODE', {
      displayMode: mode,
    })
    onChangeDisplayMode(mode)
  }

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newMode: DiscountsDisplayEnum | null
  ) => {
    if (newMode !== null) {
      changeDisplayMode(newMode)
    }
  }

  return (
    <StyledToggleButtonGroup
      value={displayMode}
      exclusive
      onChange={handleChange}
      aria-label="display mode"
      size="small"
    >
      <StyledToggleButton value={DiscountsDisplayEnum.LIST} aria-label="list view">
        <ViewListRoundedIcon fontSize="small" />
        Lista
      </StyledToggleButton>
      <StyledToggleButton value={DiscountsDisplayEnum.TABLE} aria-label="table view">
        <TableChartRoundedIcon fontSize="small" />
        Tabla
      </StyledToggleButton>
      <StyledToggleButton value={DiscountsDisplayEnum.MAP} aria-label="map view">
        <MapRoundedIcon fontSize="small" />
        Mapa
      </StyledToggleButton>
    </StyledToggleButtonGroup>
  )
}
