import React, { useContext, useState } from 'react'
import { Avatar, Box, IconButton, Typography, Container, Divider, Link } from '@mui/material'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import { AppContext } from '../../../contexts/AppContext'
import { SMMuiTelInput, SMTextField } from '../../../SMComponents/SMInput'
import { SMPrimaryButton } from '../../../SMComponents/SMButton'
import { request } from '../../../helpers/request'
import {
  formatRut,
  isValidRut,
  isValidPhoneNumber,
  isValidName,
  isValidUserName,
  isValidEmail,
} from '../../../helpers/validateForms'
import { logSMEvent } from '../../../events/logEvent'
import { DiscountsViewsEnum } from '../../../enums/DiscountsViewsEnum'
import { SMView } from '../../../SMComponents/SMView'
import { appVersion } from '../../../consts/version'

export const ProfileScreen = ({ setView }: { setView: (view: DiscountsViewsEnum) => void }) => {
  const { auth, setAuth, handleOpenSnackBar, user: userFrontendRandomId } = useContext(AppContext)
  const [name, setName] = useState<string>(auth && auth.name ? auth.name : '')
  const [username, setUsername] = useState<string>(auth && auth.username ? auth.username : '')
  const [email, setEmail] = useState<string>(auth && auth.email ? auth.email : '')
  const [rut, setRut] = useState<string>(auth && auth.rut ? auth.rut : '')
  const [phoneNumber, setPhoneNumber] = useState<string>(
    auth && auth.phoneNumber ? auth.phoneNumber : '+56'
  )
  const [isLoading, setIsLoading] = useState(false)

  const handlePhoneNumberChange = (newValue: string) => {
    setPhoneNumber(newValue)
  }

  const handleRutChange = (e: any) => {
    const rut = formatRut(e.target.value)
    setRut(rut)
  }

  const handleCreateUser = async () => {
    setIsLoading(true)
    logSMEvent('CREATE_USER', {
      name,
      phoneNumber,
      username,
      rut,
      email,
    })
    const response = await request('create_user', {
      method: 'POST',
      body: JSON.stringify({
        name,
        phoneNumber,
        username,
        rut,
        email,
        userFrontendRandomId,
        source: 'profile_screen',
      }),
    })
    setAuth(response.data)
    handleOpenSnackBar({
      message: 'Perfil actualizado',
    })
    setIsLoading(false)
  }

  return (
    <SMView sx={{ backgroundColor: '#F8F9FE' }}>
      {/* Header */}
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 10,
          backgroundColor: 'white',
          borderBottom: '1px solid',
          borderColor: 'rgba(0, 0, 0, 0.06)',
          px: 2,
          py: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconButton
          onClick={() => setView(DiscountsViewsEnum.DISCOUNT_DISPLAY)}
          sx={{
            color: 'text.primary',
            mr: 1,
          }}
        >
          <ArrowBackIosNewRoundedIcon sx={{ fontSize: 20 }} />
        </IconButton>
        <Typography
          variant="h6"
          sx={{
            flex: 1,
            fontSize: '1.125rem',
            fontWeight: 600,
          }}
        >
          Perfil
        </Typography>
      </Box>

      <Container maxWidth="sm" sx={{ px: 2, py: 3 }}>
        {/* Profile Header */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mb: 4,
          }}
        >
          <Box sx={{ position: 'relative', mb: 3 }}>
            <Avatar
              sx={{
                width: 100,
                height: 100,
                backgroundColor: 'primary.main',
                fontSize: '2.5rem',
              }}
            >
              {name ? name.charAt(0).toUpperCase() : <PersonRoundedIcon sx={{ fontSize: 40 }} />}
            </Avatar>
            <IconButton
              size="small"
              sx={{
                position: 'absolute',
                bottom: 0,
                right: -4,
                backgroundColor: 'white',
                boxShadow: 1,
                border: '2px solid white',
                '&:hover': {
                  backgroundColor: 'grey.100',
                },
              }}
              onClick={() => {
                logSMEvent('EDIT_PROFILE_ICON_BUTTON_CLICKED', { appVersion })
              }}
            >
              <EditRoundedIcon sx={{ fontSize: 16 }} />
            </IconButton>
          </Box>
        </Box>

        {/* Form Fields */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}>
          <SMTextField
            label="Nombre completo"
            value={name}
            onChange={(event: any) => setName(event.target.value)}
            validateOnBlur={isValidName}
            textOnBlurError="Por favor ingresa tu nombre completo"
            placeholder="Ej: Juan Pérez"
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white',
                borderRadius: '12px',
              },
            }}
          />

          <SMTextField
            label="Nombre de usuario"
            value={username}
            onChange={(event: any) => setUsername(event.target.value)}
            validateOnBlur={isValidUserName}
            textOnBlurError="Mínimo 3 caracteres"
            placeholder="Ej: juanperez"
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white',
                borderRadius: '12px',
              },
            }}
          />

          <SMTextField
            label="Correo electrónico"
            value={email}
            onChange={(event: any) => setEmail(event.target.value.trim())}
            validateOnBlur={isValidEmail}
            textOnBlurError="Correo inválido"
            placeholder="correo@ejemplo.com"
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white',
                borderRadius: '12px',
              },
            }}
          />

          <SMMuiTelInput
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            validateOnBlur={isValidPhoneNumber}
            textOnBlurError="Número inválido"
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white',
                borderRadius: '12px',
              },
            }}
          />

          <SMTextField
            label="RUT"
            value={rut}
            onChange={handleRutChange}
            validateOnBlur={isValidRut}
            textOnBlurError="RUT inválido"
            placeholder="11.111.111-1"
            disabled={Boolean(auth && auth?.rut)}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white',
                borderRadius: '12px',
              },
            }}
          />
        </Box>

        <Box sx={{ mt: 4 }}>
          <Typography
            variant="caption"
            sx={{
              color: 'text.secondary',
              display: 'block',
              textAlign: 'center',
              px: 2,
              mb: 3,
            }}
          >
            Al guardar, aceptas nuestros{' '}
            <Link
              href="https://www.savemoney.cl/terminos.html"
              target="_blank"
              rel="noopener"
              sx={{
                color: 'primary.main',
                textDecoration: 'none',
                fontWeight: 500,
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              términos y condiciones
            </Link>
          </Typography>
        </Box>
      </Container>

      {/* Footer */}
      <Box
        sx={{
          position: 'sticky',
          bottom: 0,
          borderTop: '1px solid',
          borderColor: 'rgba(0, 0, 0, 0.06)',
          p: 2,
          display: 'flex',
          justifyContent: 'center',
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(248, 249, 254, 0.8)',
        }}
      >
        <Box sx={{ width: '400px' }}>
          <SMPrimaryButton
            disabled={
              !isValidPhoneNumber(phoneNumber) ||
              !isValidName() ||
              !isValidRut(rut) ||
              !isValidUserName(username) ||
              !isValidEmail(email) ||
              isLoading
            }
            onClick={handleCreateUser}
            loading={isLoading}
            fullWidth
            sx={{
              py: 1.75,
              fontSize: '0.9375rem',
              fontWeight: 600,
              borderRadius: '12px',
              textTransform: 'none',
              boxShadow: 'none',
            }}
          >
            {isLoading ? 'Guardando...' : 'Guardar cambios'}
          </SMPrimaryButton>
        </Box>
      </Box>
    </SMView>
  )
}
