import { createContext } from 'react'
import { LoginDrawerFieldsEnum } from '../enums/LoginDrawerFieldsEnum'
import { UserAuthType } from './AppContext'
import { Discount } from '../types/discount'
import { defaultDiscount } from '../helpers/deafultDiscount'
import { Ad } from '../types/ads'
import { Restaurant } from '../types/restaurants'
import { RestaurantReservationInfo } from '../types/reservationInfo'

export type OpenLoginDrawerProps = {
  source: string
  title: string
  subtitle: string
  fields: LoginDrawerFieldsEnum[]
  buttonLabel: string
  href?: string
  onSubmit: (user: UserAuthType) => void
}
export const defaultLoginDrawerProps: OpenLoginDrawerProps = {
  source: '',
  title: '',
  subtitle: '',
  fields: [],
  buttonLabel: '',
  onSubmit: () => {},
}

export type OpenReportErrorProps = {
  source: string
  discount: Discount
}
export const defaultReportErrorDrawerProps: OpenReportErrorProps = {
  source: '',
  discount: defaultDiscount,
}

export type OpenAdDrawerProps = {
  ad: Ad | null
  logImpressionParams: any
}

export const defaultAdDrawerProps: OpenAdDrawerProps = {
  ad: null,
  logImpressionParams: {},
}

export type OpenCuponModalProps = {
  discount: Discount
  userAuth: UserAuthType | null
}

export const defaultCuponModalProps: OpenCuponModalProps = {
  discount: defaultDiscount,
  userAuth: null,
}

export type OpenBookTableDrawerProps = {
  reservationInfo: RestaurantReservationInfo[]
}

export const defaultBookTableDrawerProps: OpenBookTableDrawerProps = {
  reservationInfo: [],
}

export type DiscountContextType = {
  openLoginDrawer: boolean
  handleOpenLoginDrawer: (props: OpenLoginDrawerProps) => void
  openReportErrorDrawer: boolean
  handleOpenReportErrorDrawer: (props: OpenReportErrorProps) => void
  handleDiscountClick: (discount: Discount | undefined) => void
  setOpenMenuDrawer: (state: boolean) => void
  openAdDrawer: boolean
  handleOpenAdDrawer: (props: OpenAdDrawerProps) => void
  openCuponModal: (props: OpenCuponModalProps) => void
  restaurantList: Restaurant[]
  openBookTableDrawer: (reservationInfo: RestaurantReservationInfo[]) => void
}

export const DiscountContext = createContext<DiscountContextType>({
  openLoginDrawer: false,
  handleOpenLoginDrawer: () => {},
  openReportErrorDrawer: false,
  handleOpenReportErrorDrawer: () => {},
  handleDiscountClick: () => {},
  setOpenMenuDrawer: () => {},
  openAdDrawer: false,
  handleOpenAdDrawer: () => {},
  openCuponModal: () => {},
  restaurantList: [],
  openBookTableDrawer: () => {},
})
