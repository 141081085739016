import { FilterOptionsEnum, FilterOptionsType } from '../../../../consts/filterOptions'
import { ADS_FRONTEND_VERSION } from '../../../../consts/version'
import {
  filtersSelectedIncludeAnyOfDays,
  filtersSelectedIncludeAnyOfUbicacion,
  filtersSelectedExplicitlyHasTarjeta,
  filtersSelectedIncludeAnyOfCategory,
} from '../../../../helpers/filterDiscounts'
import { Ad, AdTypeEnum } from '../../../../types/ads'
import { ClubEnums } from '../../../../types/clubs'

const matchRules = (ad: Ad, filtersSelected: FilterOptionsType) => {
  if (!ad.rules) return true
  if (
    ad.rules?.Tarjeta &&
    !filtersSelectedExplicitlyHasTarjeta(filtersSelected, ad.rules.Tarjeta)
  ) {
    return false
  }
  if (ad.rules?.Dias && !filtersSelectedIncludeAnyOfDays(filtersSelected, ad.rules.Dias)) {
    return false
  }
  if (
    ad.rules?.Ubicacion &&
    !filtersSelectedIncludeAnyOfUbicacion(filtersSelected, ad.rules.Ubicacion)
  ) {
    return false
  }
  if (
    ad.rules?.Categories &&
    !filtersSelectedIncludeAnyOfCategory(filtersSelected, ad.rules.Categories)
  ) {
    return false
  }
  return true
}

const genericFilterOfAds = (ads: Ad[], adType: AdTypeEnum) => {
  return ads
    ?.filter((ad) => {
      // filter none displayed ads (move to the backend)
      if (ad.display !== true) return false

      // filter via versions
      if (ad.MIN_FRONTEND_VERSION && ad.MIN_FRONTEND_VERSION > ADS_FRONTEND_VERSION) {
        return false
      }
      if (ad.MAX_FRONTEND_VERSION && ad.MAX_FRONTEND_VERSION < ADS_FRONTEND_VERSION) {
        return false
      }

      // filter by type
      if (ad.adType !== adType) return false

      return true
    })
    ?.sort((a, b) => {
      if (a.order && b.order) return a.order - b.order
      if (a.order) return -1
      if (b.order) return 1
      return 0 // no order
    })
}

export const filterBannersToShow = (ads: Ad[] | null, filtersSelected: FilterOptionsType) => {
  if (!ads) return []
  return genericFilterOfAds(ads, AdTypeEnum.BANNER)?.filter((ad) => {
    if (!matchRules(ad, filtersSelected)) return false
    return true
  })
}

export const filterAdsToShowInList = (
  ads: Ad[] | null,
  filtersSelected: FilterOptionsType
): Ad[] => {
  if (!ads) {
    return []
  }
  const isDFSelectedAndIsTheOnlyOne =
    filtersSelected[FilterOptionsEnum.TARJETAS]?.length === 1 &&
    filtersSelected[FilterOptionsEnum.TARJETAS]?.includes(ClubEnums.DF)
  if (isDFSelectedAndIsTheOnlyOne) {
    return []
  }
  return genericFilterOfAds(ads, AdTypeEnum.DISCOUNT_LIST_AD).filter((ad) => {
    if (!matchRules(ad, filtersSelected)) return false
    return true
  })
}
