import React, { useRef, useEffect } from 'react'

import { DEFAULT_FILTERS, FilterOptionsEnum } from '../../../../consts/filterOptions'
import styles from './filters.module.css'
import ButtonTracked from '../../../common/ButtonTracked'
import { neutral90White } from '../../../../style'
import FilterCardContent from './FilterCardContent'

const { filtersFooterFrame, footerButtons, resultsAmount } = styles

const FilterFooterFrame = function ({
  filterLabel,
  toggleDrawer,
}: {
  filterLabel: string
  toggleDrawer: (open: boolean) => void
}) {
  return (
    <div className={filtersFooterFrame}>
      <div className={resultsAmount} />
      <div className={footerButtons}>
        <ButtonTracked
          buttonname={`filter_card_done_${filterLabel}`}
          variant="contained"
          onClick={() => {
            return toggleDrawer(false)
          }}
          sx={{
            fontWeight: 'bold',
            textTransform: 'none',
            margin: '20px;',
            padding: '4px 20px',
            fontSize: '1rem',
          }}
        >
          Listo
        </ButtonTracked>
      </div>
    </div>
  )
}

const FilterCard = function ({
  filterLabel,
  toggleDrawer,
}: {
  filterLabel: string | false
  toggleDrawer: (open: boolean) => void
}) {
  const refs = useRef<{ [key: string]: HTMLDivElement | null }>({})

  const scrollToLabel = (label: string) => {
    const ref = refs.current[label]
    if (ref) {
      ref.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  useEffect(() => {
    if (filterLabel) {
      scrollToLabel(filterLabel)
    }
  }, [filterLabel])

  if (!filterLabel) return <div />
  if (filterLabel === 'Sort') {
    return (
      // Sort es la única opción aca
      <div>
        <FilterCardContent filterLabel={filterLabel} />
        <div style={{ height: '45px' }} />
        <FilterFooterFrame filterLabel={filterLabel} toggleDrawer={toggleDrawer} />
      </div>
    )
  }
  return (
    <div>
      {Object.keys(DEFAULT_FILTERS).map((label) => {
        if (
          label === FilterOptionsEnum.MAP ||
          label === FilterOptionsEnum.SORT ||
          label === FilterOptionsEnum.WITHOUT_G_MAPS_RESULTS ||
          label === FilterOptionsEnum.ONLY_RESERVED ||
          label === FilterOptionsEnum.WITHOUT_LOCAL ||
          label === FilterOptionsEnum.SUBTARJETA ||
          label === FilterOptionsEnum.G_MAP_RESULT_RESERVATION_INFO_NULL
        )
          return null
        const borderBottom = label === FilterOptionsEnum.BENEFIT_TYPE ? 'none' : '2px solid'
        return (
          <div
            style={{ padding: '20px 0px', borderBottom, borderColor: neutral90White }}
            ref={(el) => (refs.current[label] = el)} // Assign the ref based on the label
            key={label}
          >
            <FilterCardContent filterLabel={label} key={label} />
          </div>
        )
      })}
      <div style={{ height: '45px' }} />
      <FilterFooterFrame filterLabel={filterLabel} toggleDrawer={toggleDrawer} />
    </div>
  )
}

export default FilterCard
