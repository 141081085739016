import React from 'react'
import LocalGasStationRoundedIcon from '@mui/icons-material/LocalGasStationRounded'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import TheatersRoundedIcon from '@mui/icons-material/TheatersRounded'
import RestaurantRoundedIcon from '@mui/icons-material/RestaurantRounded'
import DeliveryDiningRoundedIcon from '@mui/icons-material/DeliveryDiningRounded'
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded'
import MedicationRoundedIcon from '@mui/icons-material/MedicationRounded'
import CoffeeRoundedIcon from '@mui/icons-material/CoffeeRounded'

import { Discount } from './discount'

export enum MAINTAGV3Enum {
  RESTOBAR_PRESENCIAL = 'RESTOBAR_PRESENCIAL',
  COMIDA_DELIVERY = 'COMIDA_DELIVERY',
  CAFES_Y_OTROS = 'CAFES_CAFETERIA_PASTELERIA_CHOCOLATERIA_DULCERIA_PANADERIA', // Cafetería, Pastelería, Chocolatería, Dulcería
  FARMACIAS = 'FARMACIAS',
  SHOPPING = 'SHOPPING',
  BENCINA = 'BENCINA',
  PANORAMAS = 'PANORAMAS',
  OTROS = 'OTROS',
}

export const ALLMAINTAGSV3 = [
  // este orden si importa para mosrar en los filtros y en la tabla
  MAINTAGV3Enum.RESTOBAR_PRESENCIAL,
  MAINTAGV3Enum.COMIDA_DELIVERY,
  MAINTAGV3Enum.CAFES_Y_OTROS,
  MAINTAGV3Enum.FARMACIAS,
  MAINTAGV3Enum.SHOPPING,
  MAINTAGV3Enum.BENCINA,
  MAINTAGV3Enum.PANORAMAS,
  MAINTAGV3Enum.OTROS,
]

export const MAINTAGV3Label = {
  [MAINTAGV3Enum.RESTOBAR_PRESENCIAL]: 'Restaurantes y Bares - Presencial',
  [MAINTAGV3Enum.COMIDA_DELIVERY]: 'Delivery de Comida',
  [MAINTAGV3Enum.CAFES_Y_OTROS]: 'Cafés y Dulcerías',
  [MAINTAGV3Enum.FARMACIAS]: 'Farmacias',
  [MAINTAGV3Enum.BENCINA]: 'Bencineras',
  [MAINTAGV3Enum.PANORAMAS]: 'Panoramas',
  [MAINTAGV3Enum.SHOPPING]: 'Shopping',
  [MAINTAGV3Enum.OTROS]: 'Otras categorías',
}

export const MAINTAGV3Icons = (style: any) => {
  return {
    [MAINTAGV3Enum.RESTOBAR_PRESENCIAL]: <RestaurantRoundedIcon style={style} />,
    [MAINTAGV3Enum.COMIDA_DELIVERY]: <DeliveryDiningRoundedIcon style={style} />,
    [MAINTAGV3Enum.CAFES_Y_OTROS]: <CoffeeRoundedIcon style={style} />,
    [MAINTAGV3Enum.FARMACIAS]: <MedicationRoundedIcon style={style} />,
    [MAINTAGV3Enum.BENCINA]: <LocalGasStationRoundedIcon style={style} />,
    [MAINTAGV3Enum.PANORAMAS]: <TheatersRoundedIcon style={style} />,
    [MAINTAGV3Enum.SHOPPING]: <ShoppingCartRoundedIcon style={style} />,
    [MAINTAGV3Enum.OTROS]: <AddCircleRoundedIcon style={style} />,
  }
}

export const MAINTAGV3LogsAb = {
  [MAINTAGV3Enum.RESTOBAR_PRESENCIAL]: 'RES_P',
  [MAINTAGV3Enum.COMIDA_DELIVERY]: 'COM_D',
  [MAINTAGV3Enum.CAFES_Y_OTROS]: 'CAF',
  [MAINTAGV3Enum.BENCINA]: 'BEN',
  [MAINTAGV3Enum.PANORAMAS]: 'PAN',
  [MAINTAGV3Enum.SHOPPING]: 'SHO',
  [MAINTAGV3Enum.FARMACIAS]: 'FAR',
  [MAINTAGV3Enum.OTROS]: 'OTH',
}

const MAINTAGV3ThatDependsOnRegions = [
  MAINTAGV3Enum.RESTOBAR_PRESENCIAL,
  MAINTAGV3Enum.COMIDA_DELIVERY,
  MAINTAGV3Enum.CAFES_Y_OTROS,
]

export const discountDependsOnRegion = (discount: Discount) => {
  return MAINTAGV3ThatDependsOnRegions.some((mt) => discount.mainTagV3?.includes(mt))
}
